.info-banner-visible navigation-header-bar {
  top: $info-banner-height;
}

navigation-header-bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: $nav-header-bar-height;
  padding: 0 24px;
  background-color: $content-bg-color;
  border-bottom: 1px solid $gray-4;
}

navbar-user-menu {
  padding-right: rem-calc(6);

  .current-user-name {
    font-size: $topbar-link-font-size;
    margin-left: rem-calc(3);
    vertical-align: text-top;
  }

  ul[pr-dropdown-body] li {
    line-height: 2.5rem;
  }

  svg {
    vertical-align: top;
    width: rem-calc(24);
    height: rem-calc(24);
    padding: rem-calc(2);
    border: rem-calc(1) solid $gray-6;
    border-radius: 50%;
    fill: $gray-7;
    background-color: $content-bg-color;
  }

  .mat-icon {
    vertical-align: top;
  }

  .toggleable-menu-item {
    display: flex;
    justify-content: space-between;

    .toggle-on, .toggle-off {
      padding-left: rem-calc(16);
      font-size: rem-calc(11);
      font-weight: $font-weight-demibold;
      text-transform: uppercase;
    }

    .toggle-on {
      color: $grass-green-6;
    }

    .toggle-off {
      color: $autumn-orange-6;
    }
  }
}
