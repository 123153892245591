risk-score-group {
    &:last-child .risk-score-container {
        margin-bottom: 0;
    }

    .risk-score-container {
        display: flex;
        align-items: flex-end;
        margin-bottom: 24px;

        .term-label {
            font-weight: normal;
            width: 10rem;
        }

        .risk-score-actions {
            margin-left: 24px;
            padding-top: 8px;
            align-self: flex-start;

            .context-button {
                padding: 4px;
                font-size: 16px;
            }
        }
    }

    .context-button {
        align-self: baseline;
        margin-left: 8px;
    }

    .fa-sticky-note {
        color: $gray-7;
    }
}

risk-score {
    .risk-active-circle {
        visibility: visible;
    }

    .inactive-score .risk-active-circle {
        visibility: hidden;
    }

    svg-icon {
        width: 78px;
        height: 26px;
        margin-left: 1px;
    }

    .risk-score-controls {
        display: flex;
    }

    .single-risk {
        display: flex;
        flex-direction: column;
        font-size: 0.75rem;
        align-items: center;
        color: $gray-9;

        &.inactive-score, &.recommended-score {
            color: $gray-6;
        }

        .INSIGNIFICANT-color, .COMPLETE-color, .DECREASING_RAPIDLY-color {
            color: $grass-green-8;
        }

        .LOW-color, .STRONG-color, .DECREASING-color {
            color: $grass-green-6;
        }

        .MODERATE-color {
            color: $autumn-orange-6;
        }

        .STABLE-color {
            color: $ds-blue-7;
        }

        .HIGH-color, .WEAK-color, .INCREASING-color {
            color: $cinnabar-red-6;
        }

        .EXTREME-color, .INEFFECTIVE-color, .INCREASING_RAPIDLY-color {
            color: $cinnabar-red-8;
        }

        &.inactive-score, &.recommended-score {
            svg-icon {
                opacity: 50%;
            }
        }
    }
}

.risk-module-icon {
    .risk-icon-background {
        fill: $gray-1;
    }

    &.module-nonpurchased {
        .risk-icon-color {
            fill: $gray-6;
        }
    }

    &.module-purchased {
        .risk-icon-color {
            fill: $ds-blue-6;
        }

        &.module-selected {
            .risk-icon-background {
                fill: $ds-blue-6;
            }

            .risk-icon-text {
                fill: white;
            }
        }
    }

    &.module-selected {
        .risk-icon-background {
            fill: $gray-6;
        }

        .risk-icon-text {
            fill: white;
        }
    }
}

risk-edit-sidebar {
    .term-label {
        font-weight: 400;
        font-size: 0.75rem;
    }
}

.risk-activity-log-item-list {
    margin-bottom: 0.5rem;

    ul {
        font-size: inherit;
        line-height: inherit;
        margin-bottom: inherit;
    }

    li {
        margin-top: 0.5rem;
    }
}

policy-evidence {
    .view-evidence-icon {
        margin-right: 5px;
    }

    .evidence-extra-space-top {
        display: block;
        padding-top: 26px;

        pr-dropdown *[pr-dropdown-trigger] {
            padding-top: unset;
        }
    }
}

.risk-suggestions-list {
    .action-button {
        margin-left: 1rem;
    }

    .suggested-items-list .ds-item-list-item {
        color: $gray-7;
        line-height: 1.2rem;
        padding-bottom: .25rem;
        padding-top: .25rem;
    }

    .fa-spinner {
        padding: 9px;
    }

    .more-suggestions {
        font-weight: 600;
        padding-bottom: .75rem;
        color: $gray-7;
    }
}

.threat-type-filter-dropdown {
    ul.ds-dropdown-body {
        max-height: none;
    }

    .dropdown-footer {
        min-width: 300px;
    }

    #ds-dropdown-footer-row {
        display: flex;
        justify-content: space-between;
    }
}

assessments-page {
    risk-assessment-filters {
        .dropdown-label {
            padding-right: 2px;
            color: $gray-7;
            font-weight: $font-weight-normal;
        }

        .dropdown-value {
            font-weight: $font-weight-demibold;
        }

        i.dropdown {
            margin-left: 4px;
            font-size: 0.5rem;
        }

        div.trigger-container:not(threat-details-form div) {
            border-bottom: none !important; // to override style defined in component
        }
    }

    upgraded-risk-assessment-header, inventory-assessment-header {
        .new-assessment-dropdown  #ds-dropdown-trigger{
            margin-bottom: 8px !important;
        }

        .export-dropdown  #ds-dropdown-trigger{
            margin-bottom: 8px !important;
        }
    }
}

$score-colors: $grass-green-8, $grass-green-6, $severity-medium-color, $severity-high-color, $severity-extreme-color;
$hover-score-colors: $grass-green-4, $grass-green-2, $autumn-orange-2, $cinnabar-red-2, $cinnabar-red-2;
$direction-score-colors: $grass-green-8, $grass-green-6, $ds-blue-7, $severity-high-color, $severity-extreme-color;
$direction-hover-score-colors: $grass-green-4, $grass-green-2, $ds-blue-2, $cinnabar-red-2, $cinnabar-red-2;

ds-button-bar.score-colors ds-button {
    @for $i from 1 through length($score-colors) {
        &:nth-child(n + #{ $i }) {
            button {
                color: nth($score-colors, $i);
            }
        }
    }

    &:hover, &.recommended {
        @for $i from 1 through length($hover-score-colors) {
            &:nth-child(n + #{ $i }) {
                button {
                    background-color: nth($hover-score-colors, $i);
                }
            }
        }
    }

    &.active {
        @for $i from 1 through length($score-colors) {
            &:nth-child(n + #{ $i }) {
                button {
                    background-color: nth($score-colors, $i);
                    border: 1px solid nth($score-colors, $i) !important;
                    color: $light-text-for-dark-background;
                }
            }
        }
    }
}

ds-button-bar.direction-score-colors ds-button {
    @for $i from 1 through length($direction-score-colors) {
        &:nth-child(n + #{ $i }) {
            button {
                color: nth($direction-score-colors, $i);
            }
        }
    }

    &:hover, &.recommended {
        @for $i from 1 through length($direction-hover-score-colors) {
            &:nth-child(n + #{ $i }) {
                button {
                    background-color: nth($direction-hover-score-colors, $i);
                }
            }
        }
    }

    &.active {
        @for $i from 1 through length($direction-score-colors) {
            &:nth-child(n + #{ $i }) {
                button {
                    background-color: nth($direction-score-colors, $i);
                    border: 1px solid nth($direction-score-colors, $i) !important;
                    color: $light-text-for-dark-background;
                }
            }
        }
    }
}

ds-button-bar.inverted-score-colors ds-button {
    @for $i from 1 through length($score-colors) {
        &:nth-child(n + #{ $i }) {
            button {
                color: nth($score-colors, length($score-colors) + 1 - $i);
            }
        }
    }

    &:hover, &.recommended {
        @for $i from 1 through length($hover-score-colors) {
            &:nth-child(n + #{ $i }) {
                button {
                    background-color: nth($hover-score-colors, length($hover-score-colors) + 1 - $i);
                }
            }
        }
    }

    &.active {
        @for $i from 1 through length($score-colors) {
            &:nth-child(n + #{ $i }) {
                button {
                    background-color: nth($score-colors, length($score-colors) + 1 - $i);
                    border: 1px solid nth($score-colors, length($score-colors) + 1 - $i) !important;
                    color: $light-text-for-dark-background;
                }
            }
        }
    }
}

.risk-state-badge {
    @extend .boxed-badge;
    margin: 0;
}

.fixed-page-container {
    &.below-top-nav-and-header-edit {
        top: 80px;
    }

    &.below-top-nav-and-header-view {
        top: 88px; /* Originally, 5rem but need 8px of margin on the bottom */
    }
}

.actions-margin {
    margin-top: 16px;
}

risk-selection-modal {
    .risk-event-type-dropdown pr-dropdown ul {
        max-height: 140px; // Each option has a height of 28px. (28px * 5 options = 140px)
    }

    ds-dropdown > pr-dropdown > ul[pr-dropdown-body] {
        overflow-y: revert;
    }
}

svg-icon.extremely-large {
    .mat-icon {
        height: 260px;
        width: 290px;
    }
}

risk-assessment-scores-modal, edit-risk-score-modal, risk-score-group, contextual-risk-score, inventory-assessment-scores-modal {
    .score-undefined {
        color: $gray-9;
    }

    .score-insignificant {
        color: $grass-green-9;
    }

    .score-low {
        color: $grass-green-6;
    }

    .score-medium {
        color: $autumn-orange-7;
    }

    .score-stable {
        color: $ds-blue-7
    }

    .score-high {
        color: $cinnabar-red-7;
    }

    .score-extreme {
        color: $cinnabar-red-9;
    }
}

risk-edit-threat-details-modal {
    .ds-dropdown-trigger {
        padding-top: 6px;
        margin-bottom: 4px;
    }
}

risk-list-header {
    .create-risk-exception-dropdown .ds-dropdown-trigger {
        margin-bottom: 2px;
    }
}

risk-assessment-view, risk-score-group {
    .warning-icon {
        margin-left: 2px;
        color: $autumn-orange-6;

        &:hover {
            color: $autumn-orange-7;
        }
    }
}


// TODO find a more general fix for this after migrating more pr-dropdowns
.ds-dropdown-body .blue-link-options-container {
    li[pr-dropdown-close-on-click] {
        padding-top: 3px;
        padding-bottom: 3px;
    }
}

active-risk-schedule-view {
    .edit-button {
        button {
            padding-top: 0;
        }
    }
}

inventory-list, internal-control-list, operational-risk-list, active-risk-schedule-view {
    .review-status-late {
        color: $cinnabar-red-7;
    }

    .review-status-almost-due {
        color: $autumn-orange-7;
    }

    .review-status-early {
        color: $grass-green-7;
    }
}

inventory-view-page {
    @media print {

        .main-page-container {
            overflow-y: visible !important;
        }

        .main-page-content {
            overflow-y: visible !important;
        }

        .main-page-sidebar {
            overflow-y: visible !important;
        }

        a[href]:after {
            content: none !important;
        }

        button, input, select, pr-dropdown, ds-dropdown, file-uploader {
            display: none !important;
        }

        a {
            text-decoration: none;
        }

        .operational-risk-section {
            display: block !important;
        }
    }
}

default-assessment-view {
    risk-heatmap {
        td:not(.left-main-label):not(.left-sub-labels):not(.bottom-sub-labels):not(.bottom-main-label) {
            opacity: .3;
        }
    }
}
