.info-banner-visible navigation-left-bar {
  top: $info-banner-height + 3.5rem;
}

navigation-left-bar {
  position: absolute;
  top: $branding-top-bar-height;
  bottom: 0;
  left: 0;
  background-color: $content-bg-color;
  overflow-x: visible;
}
