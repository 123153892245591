report-edit-page {
  .reorder-fragment-button {
    padding: rem-calc(8);
  }

  .delete-button {
    float: right;
    top: rem-calc(-4);
    right: rem-calc(4);
  }

  .hover-panel {
    border-left: rem_calc(6) solid transparent;
    padding: rem_calc(10);
    &.contents-handle-padding {
      padding: 0;
    }

    h1 { // TODO h1 should not have padding associated with it. Can remove this after heading tags have been modified
      padding: 0; // to not have extra padding added to them.
      font-size: rem-calc(16)
    }

    p {
      font-size: rem-calc(15);
      margin-bottom: 0;
    }

    &:hover {
      background-color: $body-bg-color;
      border-color: $primary-color;
    }
  }

  .perforated-border{
    border: 1px dashed $gray-6;
    margin: rem-calc(4);
    min-height: rem-calc(200);
  }

  .indent {
    padding-left: rem-calc(16);
  }

  .page-width {
    max-width: rem-calc(800);
  }
}

chart-edit-page {
  .chart-edit {
    min-height: rem-calc(200);

    .badge-number {
      padding: 50px 30px;
    }
  }
}

.table-chart {
  overflow: auto;

  table {
    width: 90%;
    margin: 0 auto;
  }
  th {
    &.table-chart-term-header {
      text-transform: none;
    }
    &.table-chart-count-header {
      border-left: 1px solid $gray-6;
      text-align: center;
      text-transform:none;
    }
  }

  td {
    overflow-wrap:break-word;
    &.table-chart-term {
      text-align: right;
      background-color: $table-header-bg-color;
      border: 0px;
      border-top: 1px solid $gray-6;
      font-size: rem-calc(12);
      font-weight: $font-weight-bold;
      color: $table-header-color;
      overflow-wrap: break-word;
    }
    &.table-chart-count {
      text-align: center;
      font-weight: $font-weight-bold;
      color: $table-header-color;
      font-size: rem-calc(12);
    }
  }
}

div#reveal-view > div {
  top: initial !important;
  left: initial !important;
}

// Remove Foundation's SCSS overrides
reveal-dashboard {
  code {
    border: unset;
    padding: unset;
    background-color: unset;
  }

  input[type=text] {
    transition: unset;
  }
}
