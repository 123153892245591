
.zen-mode-panel {
    margin-top: $branding-top-bar-height;
    background-color: $body-bg-color;

    // Causes the component that is added to the CDK overlay to always fill the whole width of the page, so that each
    // component doesn't need to specify `width: 100%`
    > :first-child {
        width: 100%;
    }
}
