.assets-list {
  ul[pr-dropdown-body].overflow-auto {
    overflow-y: auto;
  }

  .asset-count {
    font-size: rem-calc(13);
    font-weight: $font-weight-demibold;
  }

  .heartbeat-header {
    font-size: rem-calc(25);
  }
}

asset-page-search-bar {
  autocomplete-input {
    margin-left: 8px;

    input[type="text"] {
      height: 28px;
    }
  }
}

.asset-add-edit-modal {
  .mac-addresses, .ip-addresses {
    h3 {
      margin-bottom: $column-gutter;
    }
    ul {
      list-style: none;

      li {
        input, a {
          display: inline-block;
        }

        input[type="text"] {
          width: calc(100% - 2.5rem); //calc doesn't play well with rem-calc :(
        }

        a {
          width: rem-calc(32);
          text-align: center;
        }
      }
    }
  }
}

.asset-details-modal {
  table {
    margin-top: 1rem;
    width: 100%;
  }

  .ip-mac-table {
    margin-right: auto;
    margin-left: auto;
    max-width: rem-calc(500);
  }

  .add-button {
    font-size: 1rem;
    background-color: $main-accent-color;
    color: $light-text-for-dark-background;
    &:hover {
      color: $light-text-for-dark-background !important;
    }
  }

  .button-col {
    text-align: center;
    width: 3rem;
  }
}

.delete-assets-modal {
  p {
    text-align: center;
  }

  table {
    min-width: 40%;
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
    td {
      text-align: center;
    }
  }
}

#asset-settings {
  .setting-input {
    float: right;
    margin-top: rem-calc(-11);
    width: rem-calc(70);
  }
  .settings-select {
    text-align: right;
  }
  .cidr-error {
    color: $input-error-message-bg-color;
    width: auto;
    padding: rem-calc(4);
    display:block;
    font-size: rem-calc(12);
  }
  .cidr-x {
    float:right;
  }
  .cidr-text {
    display: inline;
    width: rem-calc(320);
  }
  .cidr-button {
    float: right;
    background-color: $button-bg-color;
    color: $light-text-for-dark-background;
  }
}

ul.button-group {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  li {
      margin: 0;
  }

  button {
    margin-right: rem-calc(4);
    margin-left: rem-calc(4);
  }
}
