// Threat Level icon colors
$threat-level-1-color: #FF3D32;
$threat-level-2-color: #FA8E42;
$threat-level-3-color: #4CD07D;
$threat-level-4-color: #35C4DC;

//icon sizes
threat-level-icon img {
  width: 24px;
}

info-panel {
  li threat-level-icon img {
    width: 20px;
  }
}

info-panel a {
  color: $anchor-font-color;
  &:hover,
  &:focus {
    color: $anchor-font-color-hover;
  }
}
