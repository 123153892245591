ds-button button, a.button {
    font-weight: $font-weight-demibold;
    border-radius: rem-calc(2);
    vertical-align: top;

    &:focus {
        outline: none;
    }

    &:disabled {
        pointer-events: none;
        opacity: 0.4;
    }

    &:focus-visible {
        outline: none;
        border-radius: rem-calc(4);
    }

    &.large {
        height: rem-calc(40);
        font-size: rem-calc(16);
        line-height: rem-calc(20);

        &:focus-visible {
            height: rem-calc(43);
        }
    }

    &.medium {
        height: rem-calc(32);
        font-size: rem-calc(14);
        line-height: rem-calc(16);

        &:focus-visible {
            height: rem-calc(35);
        }
    }

    &.small {
        height: rem-calc(24);
        padding: rem-calc(5) rem-calc(16);
        margin: 0 rem-calc(1);
        font-size: rem-calc(12);
        line-height: rem-calc(16);

        &:focus-visible {
            height: rem-calc(27);
        }
    }

    &.standard {
        background: $ds-blue-6;

        &:hover {
            background: $ds-blue-7;
        }

        &:focus-visible {
            background: $ds-blue-6;
            border: rem-calc(3) solid $ds-blue-2;
        }

        &:active {
            background: $ds-blue-8;
        }

        &:disabled {
            background: $ds-blue-5;
        }
    }

    &.standard-green {
        background: $grass-green-6;

        &:hover {
            background: $grass-green-7;
        }

        &:focus-visible {
            background: $grass-green-6;
            border: rem-calc(3) solid $grass-green-2;
        }

        &:active {
            background: $grass-green-8;
        }

        &:disabled {
            background: $grass-green-5;
        }
    }


    &.delete {
        background: $cinnabar-red-6;

        &:hover {
            background: $cinnabar-red-7;
        }

        &:focus-visible {
            background: $cinnabar-red-6;
            border: rem-calc(3) solid $cinnabar-red-3;
        }

        &:active {
            background: $cinnabar-red-8;
        }
    }

    &.warn {
        background: $autumn-orange-6;

        &:hover {
            background: $autumn-orange-7;
        }

        &:focus-visible {
            background: $cinnabar-red-6;
            border: rem-calc(3) solid $cinnabar-red-3;
        }

        &:active {
            background: $cinnabar-red-8;
        }
    }

    &.cancel {
        background: none;
        color: $gray-7;

        &:hover {
            background: $gray-3;
        }

        &:focus-visible {
            background: $gray-1;
            border: rem-calc(3) solid $gray-4;
        }

        &:active {
            background: $gray-4;
        }
    }

    &.outlined {
        background: $gray-1;
        color: $ds-blue-6;
        border: rem-calc(1) solid $ds-blue-6 !important;
        box-sizing: border-box;

        &:hover {
            background: $ds-blue-2;
        }

        &:focus-visible {
            background: $gray-1;
            border: rem-calc(3) solid $ds-blue-2;
        }

        &:active {
            background: $ds-blue-2;
        }
    }

    &.text {
        color: $ds-blue-6;

        &:hover {
            background: $ds-blue-1;
        }

        &:focus-visible {
            background: none;
            border: rem-calc(3) solid $ds-blue-2;
        }

        &:active {
            background: $ds-blue-2;
        }
    }

    &.blue-link {
        color: $ds-blue-6;
    }

    &.black-link {
        color: $gray-9;
    }

    &.red-link {
        color: $cinnabar-red-6;
    }

    &.blue-link, &.black-link, &.red-link {
        background: none;
        padding-left: 0;
    }

    &.blue-link, &.black-link {
        background: none;

        &:hover {
            color: $ds-blue-7;
        }

        &:focus-visible {
            color: $ds-blue-6;
        }

        &:active {
            color: $ds-blue-8;
        }
    }

    &.red-link {
        background: none;

        &:hover {
            color: $cinnabar-red-7;
        }

        &:focus-visible {
            color: $cinnabar-red-6;
        }

        &:active {
            color: $cinnabar-red-8;
        }
    }

    &.icon {
        display: flex;
        justify-content: center;
        align-items: center;

        &.large {
            width: rem-calc(40);

            i {
                font-size: 24px;
            }
        }

        &.medium {
            width: rem-calc(32);

            i {
                font-size: rem-calc(20);
            }
        }

        &.small {
            width: rem-calc(24);
        }
    }
}

ds-button-bar {
    height: 100%;

    .button-container {
        display: flex;
    }

    &[size="small"] > div {
        height: 24px;
    }

    ds-button {
        margin-right: -3px;

        button.outlined {
            border: 1px solid $gray-5 !important;
            border-radius: 0;
            color: $main-accent-color;
        }

        &.active button.outlined {
            background-color: $main-accent-color;
            border: 1px solid $main-accent-color !important;
            color: $light-text-for-dark-background;
        }

        &:first-child button {
            border-radius: 2px 0px 0px 2px;
        }

        &:last-child button {
            border-radius: 0px 2px 2px 0px;
        }
    }

    .vertical-bar {
        flex-direction: column;

        ds-button {
            &:first-child button {
                border-radius: 2px 2px 0px 0px;
            }

            &:last-child button {
                border-radius: 0px 0px 2px 2px;
            }
        }
    }
}
