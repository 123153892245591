old-dashboard-edit-page {
  $chartTileBaseWidth: rem-calc(200);
  $chartTileMargin: rem-calc(10);
  $chartTileFullWidth: $chartTileBaseWidth + ($chartTileMargin * 2);

  //Layout
  .flex-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .chart-row-max-1 {
    width: $chartTileFullWidth * 1;
  }
  .chart-row-max-2 {
    width: $chartTileFullWidth * 2;
  }
  .chart-row-max-3 {
    width: $chartTileFullWidth * 3;
  }
  .chart-row-max-4 {
    width: $chartTileFullWidth * 4;
  }
  .chart-row-max-5 {
    width: $chartTileFullWidth * 5;
  }

  .flex-space-between-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .flex-center-content {
    display: flex;
    justify-content: center;
  }

  //Style & sizes
  .chart-tile {
    width: $chartTileBaseWidth;
    border: rem-calc(1) solid $gray-6;
    margin: $chartTileMargin;

    .chart-header {
      padding: rem-calc(5);
      border-bottom: rem-calc(1) solid $gray-6;
    }

    .chart-tile-preview-icon {
      background-color: $body-bg-color;
      height: rem-calc(120);
      i {
        font-size: rem-calc(90);
        align-self: center;
      }
    }
  }

  .add-chart-dashboard {
    padding: 0;
    margin-left: $chartTileMargin;
    &:hover {
      color: $ds-blue-6;
    }
  }
}

navigation-top-bar-dashboard {
  #angle-down-space {
    margin-right: rem-calc(81);
  }
}

old-dashboard-main-page {
  .chart-row {
    display: flex;
    flex-direction: row;
  }

  .dashboard-chart {
    width: 100%;
    min-width: rem-calc(385);
    padding: rem-calc(5);
    margin: rem-calc(5);
  }
}
