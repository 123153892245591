login-page, forgot-password-page, reset-password-page, totp-authentication-page {

  .row .columns {
    margin-top: $column-gutter;
    margin-bottom: $column-gutter;
  }

  .alert-box {
    margin: 0;
  }

  form[name="forgotPassword"], form[name="login"], form[name="totpForm"] {
    button {
      text-align: center;
      width: 100%;
    }
  }

  form {
    a {
      display: block;
      font-size: 0.75rem;
      line-height: 1rem;
      margin-top: 1rem;
    }
  }
}

u2f-authentication-page {
  .info-box {
    //use flexbox to vertically center both the icon and the text
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .info-box-text {
      padding: rem_calc(10);
      font-size: $base-font-size;
    }
  }
}

// Copied from _users.scss
.reset-password {
  fieldset {
    background-color: $content-bg-color;
    legend {
      background-color: transparent;
    }
  }
}

setup-totp-page {

  .setup-2fa-offset {
    padding-left: calc(#{$nav-left-bar-width} + #{$nav-left-gutter} - #{$column-gutter});
  }

  h3 {
    font-weight: $font-weight-bold;
    margin-bottom: 16px;
  }

  .page-header {
    padding-left: 1rem;

    h1 {
      padding-left: 0;
    }
  }

  .main-page-body {
    display: flex;
    border-spacing: $column-gutter;

    .installation-instructions {
      margin: 1rem 0.5rem 1rem 1rem;
      vertical-align: top;
      border-spacing: 0;

      h3 {
        color: $gray-7;
        text-transform: uppercase;
        border-bottom: $border-rule;
      }

      .instructions-text {
        padding: $column-gutter $column-gutter*0.5;

        p, li, h3 {
          font-size: rem-calc(14);
        }
      }
    }
  }

  setup-2fa-sidebar {
    display: flex;

    .code-and-secret {
      margin: 1rem 1rem 1rem 0.5rem;
      padding: 1rem;
      width: rem-calc(300);

      .sidebar-title {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        font-size: rem-calc(14);

        color: $gray-7;
        text-transform: uppercase;
        border-bottom: $border-rule;
      }

      canvas {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }

      #read-only-code {
        margin-top: 0.5rem;
        text-align: center;
        font-size: rem-calc(16);
        font-family: monospace;
        letter-spacing: rem-calc(1);
      }
    }
  }

  .setup-complete-button {
    margin-right: 1rem;
  }
}
