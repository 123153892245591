$half-gutter: $column-gutter * 0.5;
$quarter-gutter: $half-gutter * 0.5;

.ticket-view {

  @media print {
    button, input, select, pr-dropdown, edit-mode, .filedrop, .return-to-top-footer {
      display: none;
    }
    a {
      text-decoration: none;
    }
    a[href]:after {
      content: none !important;
    }
    .fixed-page-container, .fixed-scrolling-content {
      overflow-y: visible;
    }
    .long-cell {
      width: 20rem;
    }
    .medium-cell {
      width: 12rem;
    }
    .timestamp > timestamp-dropdown > pr-dropdown {
      display: block;
      i {
        display: none;
      }
    }
  }

  // --------------------------------------------------------------------------
  // content layout
  // --------------------------------------------------------------------------

    .fixed-page-container {
    position: relative;
    right: 0;
    bottom: 0;
    top: 0;
    padding: rem-calc(3) $column-gutter * 0.5;
    overflow: visible;

    .fixed-right-content {
        position: relative;
        right: rem-calc(-22);
        top: rem-calc(10);
        min-width: rem-calc(325);
    }

    .fixed-scrolling-content {
      flex-grow: 1;
      position: relative;
      right: rem-calc(332);
      left: rem-calc(5);
      top: rem-calc(10);
      height: 79vh;
      overflow-y: scroll;

      @media print {
        height: unset;
      }

      .form-footer-space {
        margin-bottom: rem-calc(100);
      }

      .fixed-footer-notification-space {
        margin-bottom: rem-calc(180);
      }
    }
  }

  #trigger-content-container {
    width: 98%;
    display: flex;
    align-items: flex-start;
    right: 0;
    left: rem-calc(44);
    overflow-y: visible;

    @media print {
      display: unset;
      width: unset;
      position: relative;
      left: 0;
      overflow-y: initial;
    }
  }

  // --------------------------------------------------------------------------
  // content: general
  // --------------------------------------------------------------------------

  font-size: rem-calc(14);

  button.inline-add {
    color: $main-accent-color;
    font-size: rem-calc(14);
    padding: 0;

    &.with-padding {
      padding-top: 1rem;
    }

    i {
      font-size: rem-calc(14);
      padding-right: rem-calc(3);
    }
  }

  .f-dropdown li {
    color: $body-font-color;
  }

  // --------------------------------------------------------------------------
  // content: center panel
  // --------------------------------------------------------------------------

  #center-panel {
    padding: 0;

    @media print {
      position: relative;
      .fixed-size-table tbody {
        max-height: unset;
      }
    }

    article {
      margin: 0 16px;
      padding-bottom: 16px;

      &.horizontally-scrollable {
        overflow-x: auto;
      }
    }

    a.object-link {
      font-weight: $font-weight-demibold;
      color: $body-font-color;

      &:hover {
        color: $link-color;
      }
    }

    button.inline-delete {
      padding: 0 rem-calc(4);
      color: $delete-button-color;

      i {
        vertical-align: middle;
      }
    }

    table#ticket-files, table#ticket-links, table#ticket-policies {
      border: none;
      width: 100%;

      &:hover {
        button.inline-delete i {
          visibility: visible;
        }
      }
      th, td {
        padding: 0 0 rem-calc(16) 0;
        font-size: rem-calc(14);
        line-height: rem-calc(18);
        vertical-align: top;
      }
      th {
        padding-right: rem-calc(16);
        white-space: nowrap;
        font-weight: $font-weight-demibold;
        color: $sub-header;
      }
      td {
        white-space: normal;
      }
      button.inline-delete {
        margin-left: rem-calc(12);
        line-height: rem-calc(18);
      }
    }

    table#ticket-files {
      tr:last-of-type > * {
        padding-bottom: 0;
      }
    }

    table#ticket-files {
      margin-top: rem-calc(20);
    }

    .center-row-flex {
      display: flex;
      flex-direction: row;

      & dt {
        float: left;
        padding-right: 10px;
        flex: 15%;
      }
      & dd {
        flex: 85%;
        font-size: rem-calc(12);
        font-weight: $font-weight-demibold;
        padding-top: rem-calc(9.6);

        .sub-item {
          font-size: rem-calc(12);
        }
        & button {
          position: absolute;
          padding: 0 0 0 rem-calc(5);
        }
        & button i {
          opacity: 0;
        }
        &:hover button i {
          opacity: 1;
        }
      }
    }

    .section-margins {
      margin: 0 16px 32px 16px;
    }
  }

  // --------------------------------------------------------------------------
  // content: right panel
  // --------------------------------------------------------------------------

  #right-panel {
    padding: rem-calc(24) 0 rem-calc(16) 0;

    @media print {
      position: relative;
      margin-left: 0.7rem;
    }

    h3 {
      margin-top: rem-calc(12);
      margin-bottom: rem-calc(12);
    }

    .row-flex {
      display: flex;
      flex-direction: row;

      dt, dd {
        padding: rem-calc(4) 0;
        line-height: rem-calc(24);
        font-size: rem-calc(14);
      }
      dt {
        flex: 25%;
        margin: 0 rem-calc(16) 0 rem-calc(30);
        font-weight: $font-weight-demibold;
        color: $sub-header;
      }
      dd {
        flex: 75%;
        max-width: 75%;
        padding-right: 16px;
        font-weight: $font-weight-normal;
        color: $body-font-color;
      }

      label {
        margin: rem-calc(20) 0 0 0;
        padding: 0;
        border: none;
        font-size: rem-calc(12);
        line-height: 150%;
        font-weight: $font-weight-normal;
        color: $gray-6;
      }
      span.normal-text {
        font-weight: $font-weight-normal;
        color: $body-font-color;
      }
      .threat-badge, .due-date-badge, .ticket-state-badge {
        margin: 0;
      }
    }

    button.inline-edit, button.inline-delete {
      padding: 0 rem-calc(4);
      line-height: rem-calc(24);
    }

    button.inline-edit {
      color: $main-accent-color;
    }

    button.inline-delete {
      color: $delete-button-color;
    }

    .row-flex:hover {
      button.inline-edit i {
        visibility: visible;
      }
      button.inline-delete i {
        visibility: visible;
      }
    }

    .severity-badge {
      margin: 0;
      &:not(.rating-HIGH):not(.rating-MEDIUM) {
        border-color: $gray-6;
      }
    }

    trigger-name {
      color: $main-accent-color;
    }

    textarea {
      resize: vertical;
    }

    .watcher-display {
      font-size: rem-calc(14);
    }

    .watcher-title {
      font-weight: $font-weight-demibold;
    }
  }

  // --------------------------------------------------------------------------

  dt {
    padding-left: rem-calc(10);
    padding-top: rem-calc(10);
  }

  .ticket-log dt {
    @extend .banded-block-title;
    padding-top: 0;
    padding-left: 0;
  }

  ul.history-log {
    > li {
      position: relative;
      border-radius: rem-calc(2);

      .name-date-label {
        font-size: smaller;
        font-weight: $font-weight-demibold;
      }

      blockquote {
        margin-bottom: 0;
        border-left: 0px;

        @media print {
          border: 0px;
        }
      }
      dt :not(:first-of-type) {
        margin-top: 1rem;
      }
      dd {
        @extend .banded-block-content;
        margin: 0;

        i { // TODO - make policies embedded in ticketLog hide X's in another way so all i's aren't hidden
          display:none; // Hide 'X's in activity log
        }
      }
      dd i.edit-note { // Jay's icon-hiding code hides all <i>s in ticketlog to hide delete Policy X's
        display: inline-block;
      }
      dd.ticket-link {
        a {
          padding-left: rem-calc(16);
        }
      }
      #ticket-policies {
        background-color:inherit;
      }

      .sub-note {
        border-left: 2px solid $gray-2;
        padding: rem-calc(4);
        padding-right: 0;
        margin-bottom: rem-calc(8);
        margin-left: rem-calc(9);
        min-height: rem-calc(19);
      }
    }
  }

  .more-button {
    margin-bottom: $half-gutter;
  }

  @media print {
    div > form[name='ticketFileForm'] {
      display: none;
    }
  }
}

ticket-searches {
  .limit-results-height {
    max-height: rem-calc(200);
  }

  .query-row {
    display: flex;
    margin-bottom: rem-calc(16);
  }
}

generate-ticket-report-modal {
  .parameter-label {
    margin: 0;
  }
  .sub-header {
    margin-left: 0;
    margin-top: 0;
  }
  p {
    margin: rem-calc(10) 0;
    font-size: rem-calc(14);
  }
}

.grouped-table {
  @extend .hover-table;

  background: transparent;

  tbody {
    td {
      border-top: unset;
    }

    &.group-spacer {
      tr {
        background-color: transparent;
      }

      td {
        padding: 5px;
      }
    }

    &.body-group {
      box-shadow: 0 1px 4px 0 rgba($box-shadow-base-color, 0.25);
    }
  }
}

task-schedule {
  .task-schedule-interval, a {
    color: $primary-color;
  }
}

due-date-badge {
  .due-date-badge {
    @extend .boxed-badge;

    border-color: $gray-6;
    background-color: $gray-1;

    &.is-closed {
      background-color: $gray-5;
    }

    &.is-overdue {
      border-color: $severity-high-color;
      background-color: $severity-high-color;
      color: $light-text-for-dark-background;
    }

    &.was-overdue {
      border-color: $cinnabar-red-3;
      background-color: $cinnabar-red-4;
      color: $light-text-for-dark-background;
    }
  }
}

ticket-state-badge {
  .ticket-state-badge {
    @extend .boxed-badge;

    border-color: $gray-5;
    background-color: $gray-2;

    &.is-closed {
      background-color: $gray-4;
    }
  }
}

ticket-list-page-grouped-list,
ticket-list-page-default-list,
ticket-list-page-due-date-list,
task-schedule-list-page-grouped-list,
task-schedule-list-page-default-list,
library-list-page-grouped-list,
parent-and-subtasks,
parent-and-subschedules {
  tr {
    .subtasks-badge {
      @extend .boxed-badge;

      border-color: $gray-5;
      background-color: $gray-2;

      i {
        color: $primary-color;
      }
    }

    th.summary-column {
      min-width: 300px;
    }

    td.summary-column {

      .children-indicator {
        display: inline-block;
        align-self: center;
        width: 5px;
        height: 27px;
        min-width: 5px;
        margin: 0 6px;
        background-color: $primary-color;
      }

      .no-children-indicator {
        @extend .children-indicator;
        background-color: $gray-5;
      }

      .summary-container {
        display: flex;

        .fa-fw {
          flex-shrink: 0;
        }
      }

      .children-expander {
        align-self: center;
        color: $gray-8;
      }

      .children-indentation {
        width: 20px;
        height: 20px;
        min-width: 20px;
      }
    }
  }

  tr.grayed-out {
    color: $gray-6;
    background-color: $gray-2;

    .task-title, task-schedule a {
      color: $gray-6;
    }

    .subtasks-badge i {
      color: unset;
    }

    .summary-column {
      .children-expander {
        color: $gray-6;
      }

      .children-indicator {
        background-color: $gray-5;
      }
    }
  }

}

ticket-list-page-grouped-list {
  tr {
    .summary-column {
      padding-left: 0;
    }
    .task-title {
      vertical-align: top;
      display: inline-block;
    }

    .ticket-select-icon {
      color: $gray-6;

      .fa-square-check {
        color: $gray-9;
      }
    }
  }

  tr.grayed-out {
    .ticket-state-badge:not(.is-closed) {
      border-color: $gray-5;
      background-color: $gray-2;
    }

    .due-date-badge:not(.is-overdue) {
      border-color: $gray-5;
      background-color: $gray-2;
    }

    .due-date-badge.is-overdue,
    .due-date-badge.was-overdue {
      border-color: $cinnabar-red-3;
      background-color: $cinnabar-red-4;
    }
  }
}

ticket-list-page-due-date-list {

  .due-date-group-label {
    color: $gray-10;
    font-weight: 500;
    padding-top: 20px;
    font-size: large;
  }

  .due-date-group-color {
    background-color: $body-bg-color;
  }

  .due-date-title {
    display: inline-block;
  }

  .due-date-view-all {
    padding-right: 15px;
  }
}

parent-and-subtasks,
parent-and-subschedules {
  .task-title {
    vertical-align: top;
    display: inline-block;
  }

  .self-indicator {
    color: $primary-color;
    align-self: center;
  }

  .double-children-indentation {
    width: 40px;
    height: 20px;
    min-width: 40px;
  }

  .hover-table tr {
    // Used to cause the same spacing as a `children-indicator` but without actually showing it, so the summary's row
    // aligns with the task's row
    td {
      &.summary-column .spacer-children-indicator {
        @extend .children-indicator;
        background-color: transparent;
      }

      &.schedule-cell {
        width: rem-calc(150);
      }

      // The `marked` library introduces a <p> element that changes the font-size, so reverting that
      .de-emphasize p {
        font-size: unset;
      }
    }

    &.no-bottom-border td {
      border-bottom: unset;
    }

    &.summary-note-row td {
      border-top: unset;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  pr-dropdown a[pr-dropdown-trigger] {
    color: $gray-6;
    &:hover, &.active {
      color: $primary-color;
    }
  }

  tr.dimmed-row {
    background-color: $gray-2;
  }
}

ticket-add-edit-modal, add-edit-ticket-modal {
  // Make dt's look like labels
  dt {
    @extend label;
  }

  .due-date-badge {
    margin: 0;
  }
}

library-list-page-grouped-list {
    tr {
        .summary-column {
            padding-left: 0;
        }
    }
    .copied-to-org {
        color: $success-color;
        font-size: inherit;
    }
}

.filter-label-container {
  display: flex;
  align-items: center;

  .filter-label {
    color: white;
    background-color: $gray-7;
    padding: 0.25rem;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    line-height: 1;
    font-weight: 600;
  }
}

ticket-watcher-select {
  .watcher-display {
    font-weight: 400;
    color: $gray-9;
  }
}

ticket-history {
  .action-buttons {
    margin-top: rem-calc(4);
  }
}

ticket-log {
  .note-container {
    display: flex;
    flex-direction: column;
  }

  .add-reply-button {
    text-align: right;
  }

  .action-buttons {
    margin-top: rem-calc(4);
  }
}

similar-incident, incident-similarity-modal {
  svg-icon.bar-icon .mat-icon {
    height: 8px;
    line-height: 0.25rem;
    width: 20px;
    margin-right: 4px;
    cursor: pointer;
  }

  .incident-text {
    margin-top: 4px;
    font-weight: $font-weight-demibold;
    line-height: 1rem;
    color: $gray-8;

    &:hover {
      color: $ds-blue-6;
    }
  }
}

incident-preview {
  similar-incident-icons {
    svg-icon.bar-icon {
      .mat-icon {
        height: 12px;
        width: 20px;
        line-height: 0.5rem;
        cursor: pointer;
        fill: $gray-6;
      }
    }
  }
}

ticket-due-date {
  ds-button button.blue-link {
    padding-top: 0;
  }
}

//
// For Ticket Inbox
//
// note: we use !important reluctantly to override certain foundation rules
.action-header {
  &.action-group {
    justify-content: space-between;

    > nav {
      height: $nav-top-bar-height;
      margin: rem-calc(8) rem-calc(40) rem-calc(4) rem-calc(4);

      .top-bar-section > ul > li {
        background: none;

        &:not(:last-child) {
          margin-right: rem-calc(16);
        }
        &:hover > a {
          background: none;
        }
        > a {
          padding: 0 !important;
          $font-size: rem-calc(15);
          $line-height: $font-size + rem-calc(20);
          font-size: $font-size;
          line-height: $line-height !important;
          margin-top: $nav-top-bar-height - $line-height;
        }
        &.nav-top-item-main > a, .nav-top-item-main-text, .main-item-no-link {
          $font-size: rem-calc(28);
          $line-height: $font-size + rem-calc(20);
          font-size: $font-size;
          line-height: $line-height;
          margin-top: $nav-top-bar-height - $line-height;
          font-weight: $font-weight-bold;
        }
      }

      // active and on-hover states of top-bar items (underline)
      .top-bar-section > ul > li {
        &.active > a, &.not-click > a, > a:hover {
          position: relative;

          // we use :before, because has-dropdown uses :after
          &:before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: rem-calc(4);
          }
        }
        &.active:not(.nav-top-item-main) > a {
          font-weight: $font-weight-demibold;
        }
      }

      // color of underline of top-bar items
      .top-bar-section.nav-section-main > ul > li {
        &.active > a, &.not-click > a, > a:hover {
          &:before {
            background-color: $main-accent-color;
          }
        }
      }
    }
  }
}

query-finder {
  .search-query-results {
    max-height: 380px;
  }
}

saved-query-modal {
  .pr-dropdown-button {
    &:hover, &.active, &:focus {
      &:not(.button-dropdown, .link-dropdown) {
        border-bottom: none;
      }
    }
  }

  modal-buttons #cancel-button button.cancel {
    color: $ds-blue-6;
  }

  modal-buttons #close-button button.cancel {
    color: $ds-blue-6;
  }

  query-finder {
    .search-query-results {
      max-height: 410px;
    }
  }
}

ticket-escalation-section {
  ds-button.manage-escalation-settings-button > button {
    padding: 0;
    justify-content: flex-end;

    i {
      font-size: 16px;
    }
  }

  ds-button.escalate-ticket-button > button {
    font-size: 14px;
    height: 26px;
  }
}

modified-query-form {
  query-finder {
    .search-query-results {
      max-height: 320px;
    }
  }
}

saved-query-selector {
  referenced-objects related-objects-list {
    table:not(.mat-calendar-table) {
      thead, tfoot {
        tr th, tr td {
          color: $gray-7;
        }
      }
      tbody td {
        &.link-cell {
          & > :first-of-type {
            margin-left: 6px;
          }
        }
      }
    }
  }
}

collapsible-trigger-description {
  .formatted-trigger-desc {
    > span * {
      line-height: 16px;
      font-size: 14px;
    }

    > span > *, > span > ol li {
      margin-bottom: 16px;
    }
  }
}
