add-indicators-by-typing-section autocomplete-input > input.ng-invalid {
    // from _base.scss but the existing style would not apply the styles when the input is focused
    border-bottom: 1px solid $alert-color !important;
    background: $cinnabar-red-1 !important;
}

watchlist-list td.indicator-count-column > ds-button > button {
    // override the button's right padding because this cell is right aligned
    padding-right: 0;
}

view-edit-indicators-modal indicators-list-header h1 {
    // override styles applied by material-customizations in the header title and pagination-summary
    border-bottom: none !important;
    text-transform: none !important;
}