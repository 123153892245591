.info-banner-visible .navigation-top-bar {
  top: $nav-header-bar-height + $info-banner-height;
}

.navigation-top-bar {
  position: absolute;
  display: block;
  top: $nav-header-bar-height;
  left: calc(#{$nav-left-bar-width} + #{$nav-left-gutter});
  right: 0;
  height: $nav-top-bar-height;

  // note: modify $nav-top-bar-height to the new tab nav height and change the value before to use the variable
  > nav.new-tab-nav {
    height: $nav-top-bar-height !important;
    border-bottom: $border-rule;

    &.no-bottom-border {
      border-bottom: none;
    }
  }

  .new-tab-nav {
    .top-bar-section {
      display: flex;
      flex-direction: column;

      > ul {
        display: flex;
        align-items: flex-end;

        > li {
          background: none;

          &:not(:last-child) {
            margin-right: 56px;
          }

          &:hover > a {
            background: none !important;
          }

          > a {
            margin-top: 0;
            white-space: nowrap;
            padding: 0 !important;
            $font-size: 0.9375rem; // originally, 15px
            $line-height: $font-size + 1.25rem;
            font-size: $font-size;
            line-height: $line-height !important;
          }

          &.active > a, &.not-click > a, > a:hover {
            position: relative;

            // we use :before, because has-dropdown uses :after
            &:before {
              content: "";
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              height: 4px;
            }
          }

          &.active:not(.nav-top-item-main) > a {
            font-weight: $font-weight-demibold;
          }

          &.nav-top-item-main {
            &.active > a, &.not-click > a, > a:hover {
              &:before {
                right: auto;
                width: 32px;
              }
            }
          }
        }
      }

      h1.nav-top-item-main {
        font-size: 24px;
        font-weight: $font-weight-demibold;
        float: left;
        margin-top: 16px;
      }

      // color of underline of top-bar items
      &.nav-section-main > ul > li {
        &.active > a, &.not-click > a, > a:hover {
          &:before {
            background-color: $main-accent-color;
          }
        }
      }
    }
  }
}

.top-bar-section ul.no-display-inline {
  display: block;
}

.dropdown-item-full-width {
  width: 100%;
}
