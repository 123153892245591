.alerts {
  // don't let the pause text bump the pause button up when it's visible. Then, because that uncenters the pause text,
  // recenter it with absolute positioning.
  td.absolute-container {
    text-align: center; //make not-absolute-positioned content (pause button) centered too
    position: relative;
    .absolute-centered {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      bottom: 0;
    }
  }
}

.classifiers {
  input[type="checkbox"].pr-dropdown-body {
      pointer-events: none;
  }

  input[type="text"], textarea, small {
    width: calc(100% - 2rem);
  }

  .status input[type="checkbox"] {
    display: none;
  }

  input:not(.classifier-search-input), select, textarea, small {
    margin: .5rem;
    display: inline-block;
  }

  fieldset {
    padding: .5rem;
    margin: .5rem 0;
  }

  .metadata input[type="text"], select {
    width: 15rem;
  }

  ul {
    list-style: none;
    margin: 0;
  }

  tag-panel {
    margin: 0;

    action-buttons {
      hr {
        display: none;
      }
    }
  }
}
