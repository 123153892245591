@mixin border-rule($top-size, $right-size, $bottom-size, $left-size) {
  border-color: $body-border-color;
  border-style: solid;
  border-width: $top-size $right-size $bottom-size $left-size;
}

@mixin input-placeholder { // for styling placeholder text of input elements
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}
}

///
/// Structure
///

html, body {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;

  @media print {
    overflow: visible;
    position: relative;
    width: 1200px;
  }
  nav, .no-print {
    @media print {
      display: none;
    }
  }
}

// main content of the screen
main {
  position: absolute;
  top: calc(#{$nav-header-bar-height} + #{$nav-top-bar-height});
  right: 0;
  bottom: 0;
  left: 0; // will be overridden below, if required
  margin: 0;
  padding: $column-gutter $column-gutter * 0.5; // this provides even spacing, iif the grid is used; use the grid
  overflow-y: auto;
  font-size: rem-calc(14);

  &.info-banner-visible {
    top: calc(#{$nav-header-bar-height} + #{$nav-top-bar-height} + #{$info-banner-height});
  }

  @media print {
    position: relative;
    overflow-y: inherit;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
  }

  &.offset {
    left: calc(#{$nav-left-bar-width} + #{$nav-left-gutter} - #{$column-gutter});
    @media print {
      left: 0;
    }
  }

  &.new-tab-offset {
    top: -16px;
    padding: 0;
  }
}
//This class is to be used on pages that only have a table or single rowed content. The rows,columns, small-X, etc.
//all have padding attached to them, this class aligns containers with those row/column containers so we have consistent
//alignment with the headers.
.main-container {
  padding: 0;
  margin: 0 8px;
}

.sheet {
  background-color: $content-bg-color;

  // boxes can ignore the top-margin of their first element without this
  &.overflow-auto {
    overflow: auto;
  }
}

.padded {
  padding: $column-gutter*0.5;
}

.double-padded {
  padding: $column-gutter;
}

.margin-bottom {
  margin-bottom: rem-calc(25);
}

.margin-right {
  margin-right: rem-calc(25);
}

.no-margin {
  margin: 0 !important;
}

.spaced {
  margin: $column-gutter*0.5;
}

.full-width {
  width: 100%;
}

.position-relative {
  position: relative;
}

.full-sized {
  width: 100%;
  height: 100%;
}

.auto-min-inline-size {
  min-inline-size: auto;
}

.action-header {
  display: flex;
  align-items: flex-end;
  margin-bottom: 8px;
  padding: 0 8px;

  &.action-group {
    justify-content: space-between;
  }

  h1.sub-header {
    display: inline-block;
    line-height: 2.5rem;
    font-weight: 500;
    font-size: 0.825rem;
    text-transform: none;
    vertical-align: bottom;
    margin: 0;
    padding: 0;
    color: $body-font-color;
    border-bottom: none;
  }
}

@mixin search-box-mixin {
  .search-filter {
    margin-left: auto;
    height: 34px;

    input[type=text] {
      display: inline-block;
      width: 280px;

      &:focus {
        background-color: transparent;
      }

      @include input-placeholder { // styles placeholder text
        color: $input-placeholder-font-color;
      }
    }

    button {
      padding: 0;
      margin-left: -16px;
      background-color: transparent;
      color: $main-accent-color;

      i {
        vertical-align: middle;
      }
    }
  }
}

.content-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: rem-calc(14);
  // pad all directions but the bottom, the content-header should rest directly atop the content
  padding: rem-calc(8) rem-calc(12) 0 rem-calc(8);
  border-bottom: $border-rule;

  pr-dropdown {
    margin: 0 rem-calc(5) 0 rem-calc(5);
  }

  ds-dropdown {
    .ds-dropdown-trigger {
      margin-top: 0;

      &:hover {
        color: $ds-blue-6;
      }
    }

    .trigger-container {
      margin-top: 4px;
      margin-bottom: 0;
      border-bottom: 0;
    }
  }

  //LEGACY: some headers have text h2 elements. tell the h2's to be full-width (needed when we added display: flex to
  // content-header)
  h2 {
    width: 100%;
  }

  @include search-box-mixin;

  .filter-label {
    max-width: 30em;
    display: inline-block;
    vertical-align: middle;
  }
}

ds-dropdown {
  #ds-dropdown-trigger {
    flex-grow: 1;
    font-weight: $font-weight-demibold;
  }

  .dropdown-label {
    padding-right: 6px;
  }

  .dropdown-value {
    padding-right: 3px;
  }
}

// A ds-dropdown's body is not inside of the ds-dropdown in the DOM, so its CSS needs to be outside
ul.ds-dropdown-body {
  margin-left: 0;
  margin-bottom: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border: none;
  border-top: 4px solid $main-accent-color;
  box-shadow: $menu-box-shadow;
  background-color: $content-bg-color;
  color: $body-font-color;
  font-size: 14px;
  list-style-type: none;
  max-height: 300px;
  overflow-y: auto;

  @include search-box-mixin;
  .search-filter input[type=text] {
    width: 100%;
  }

  #ds-dropdown-footer-row {
    font-weight: normal;
    flex-grow: 1;
  }

  /*div.active .trigger-container {
    border-bottom: 1px solid $main-accent-color;
  }*/

  li {
    padding: 0 16px;

    &:hover {
      background-color: $ds-blue-2;
    }
  }
}

tag-search-multiselect-dropdown {
  ds-dropdown pr-dropdown {
    // To mitigate squashing the options container when the footer is hidden
    .options-container {
      padding-bottom: 12px;
    }

    *[pr-dropdown-body] {
      // The autocomplete dropdown has a z-index of 1000
      // The multiselect dropdown needs to have a smaller z-index than the autocomplete dropdown
      z-index: z-index(dropdown) - 100;
    }
  }
}

global-tag-search-bar {
  .search-input {
    width: 100%;

    .underlined-text-box:focus {
      background: #FFFFFF;
    }

    input[type=text] {
      overflow: hidden;
      height: 40px;
      border: none;
      padding: 0px 16px 0px 8px;
      &:hover:not(:disabled) {
        background: none;
      }
      //flex-grow: 2;
    }
  }
}

.hide-header-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 50em;
  white-space:  nowrap;
}

.center {
  text-align: center;
}

.left-text {
  text-align: left;
}

.right-text {
  text-align: right;
}

.right {
  float: right;
}

.no-text-transform {
  text-transform: none !important;
}

.flex-right {
  justify-content: flex-end;
}

.flex-container {
  display: flex;
}

.flex-child-same-width {
  flex: 1 1 0px
}

.flex-container-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.flex-container-column {
  display: flex;
  flex-direction: column;
}

.flex-container-item {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 0;
  padding: 0 $column-gutter * 0.5;
}

.fixed-page-container {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  padding: 0 $column-gutter;
  overflow-y: hidden;

  .fixed-right-content {
    position: absolute;
    right: rem-calc(10);
    width: rem-calc(310);
  }

  .fixed-scrolling-content {
    position: absolute;
    right: rem-calc(332);
    left: rem-calc(5);
    height: 100%;
    overflow-y: scroll;

    .form-footer-space {
      margin-bottom: rem-calc(100);
    }

    .fixed-footer-notification-space {
      margin-bottom: rem-calc(180);
    }
  }

  .fixed-save-footer {
    position: absolute;
    height: rem-calc(75);
    right: rem-calc(332);
    left: rem-calc(5);
    bottom: 0;
    padding: 0 rem-calc(15);
    background-color: $content-bg-color;
  }

  .fixed-full-width {
    right: rem-calc(16);
  }

  &.below-top-nav-and-header {
    top: rem-calc(60);
  }
}

.fixed-container-top-buffer {
  top: rem-calc(60);
}

.fixed-container-top-buffer-small {
  top: rem-calc(15);
}

.section-container {
  padding: 0 rem-calc(25);
}

trigger-description-display {
  .section-container:nth-of-type(n+2) {
    .row {
      border-top: 1px solid $gray-5;
      margin-top: rem-calc(5);
      padding-top: rem-calc(5);
    }
  }
}

.interval-element {
  padding: 0 rem-calc(10);
}

///
/// Pseudo selectors
///

:focus {
  outline: none;
}

*::selection {
  color: $light-text-for-dark-background;
  background-color: $gray-7;
}

.hidden {
  display: none !important;
}

///
/// Animations
///
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
.fade_in {
  animation: fadeIn 1s linear;
}

@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}
.fade_out {
  animation: fadeOut 1s linear;
}

///
/// Attributes
///

[ng-click], [ui-sref], .click-target {
  cursor: pointer;
}

// sometimes an element has an ng-click on it, but the click only happens when a condition is true,
// HACK use this when the condition is false in order to use the default cursor instead of the pointer
.default-cursor {
  cursor: default;
}

///
/// Elements
///

dl {
  dd {
    font-size: smaller;
  }
}

.nowrap {
  white-space: nowrap;
}
.pre-line {
  white-space: pre-line;
}

.row {
  max-width: none !important;
}

///
/// Headers
///

h1 {
  display: inline-block;
  padding: 0;
  line-height: 40px;
  font-weight: $font-weight-bold;
}

h2 {
  border-bottom: $border-rule;
  padding: 0 $column-gutter*0.5;
  font-weight: $font-weight-bold;
  line-height: rem-calc(40);

  small {
    display: inline-block;
    font-size: rem-calc(12);
    line-height: rem-calc(18);
    margin: 0 $column-gutter*0.5;
  }

  a.pr-button {
    margin-top: $column-gutter*0.5;
  }

  .settings {
    button {
      color: $body-font-color;
    }
  }

  ul.button-group {
    margin-left: $inline-list-default-float-list-margin;

    li:first-child button {
      border-left: none;
    }

    button, .button {
      margin-left: rem-calc(5);

      &:focus {
        outline: none;
      }
    }
  }
}

.sub-header {
  margin: 16px 14px;
  border-bottom: $border-rule;
  line-height: 1.5rem;
  font-size: 0.75rem;
  font-weight: $font-weight-demibold;
  color: $sub-header;
  text-transform: uppercase;
}

.term-label {
  font-weight: $font-weight-demibold;
  color: $gray-7;
}

///
/// Text
///

.de-emphasize {
  color: $sub-header;
  font-weight: $font-weight-normal;
  font-size: 90%;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.standard-font-weight {
  font-weight: $font-weight-normal;
}

///
/// Tables
///

table:not(.mat-calendar-table) {
  border-collapse: collapse;
  margin: 0;

  thead tr th, tbody tr td, tfoot tr td {
    text-align: left;
    white-space: normal;
  }

  thead, tfoot {
    tr th, tr td {
      padding: rem-calc(4) rem-calc(10);
      background-color: $table-header-bg-color;
      color: $table-header-color;
      text-transform: uppercase;
      font-size: rem-calc(12);
      font-weight: $font-weight-bold;
    }
  }

  tbody {
    tr {
      background-color: $content-bg-color;
      color: $body-font-color;
    }
  }
}

table[dsTable] {
  th[dsResizableColumn] {
    position: relative;

    div.resize-column-marker {
      position: absolute;
      cursor: ew-resize;
      height: 100%;
      width: 10px;
      right: 0;
      top: 0;
      z-index: 100;

      background: linear-gradient($gray-5, $gray-5) no-repeat center/2px 100%;
    }
  }
}

.no-left-padding {
  padding-left: 0;
}
.no-right-padding {
  padding-right: 0;
}

.fraud-inbox-trigger-view-bell-column {
  width: 1rem;
}

.hover-table {
  border: 0;

  thead th {
    // if there's an ng-click in the th, change the hover text color. Except if the class .default-cursor is present,
    // which means we are overriding the ng-click pointer, so don't color on hover.
    &[ng-click]:hover:not(.default-cursor),
    &.click-target:hover:not(.default-cursor) {
      color: $main-accent-color;
    }
  }

  tbody td {
    // Because we globally set border-collapse:collapse on the table tag, we can put a border-top and
    // border-bottom color below and having both won't make thicker lines in the middle of  the table.
    border-bottom: $border-rule;
    border-top: $border-rule;
    vertical-align: middle;

    // buttons have their own padding, so remove padding from the td so the table row doesn't render too tall
    &.button-cell {
      padding: 0;
    }

    &.link-cell:hover, &.link-cell a:hover {
      color: $link-color;
    }
    &.link-cell {
      font-weight: $font-weight-demibold;

      // Remove padding and add it back to the first child. This serves to enlarge the click target to take up
      // the entire cell. (force the child to be display:block). Usually the first child is an A tag so perhaps we
      // should directly target the first A tag child? Let's be more general for now and see where that gets us.
      padding: 0;
      & >:first-of-type {
        padding: $table-row-padding;
        display: block;

        // make all a tag children also display block; this is if the first child is a modal-view
        a {
          display: block;
        }
      }
    }
  }

  .hover-tab {
    padding: 0;
    width: 4px;
    min-width: 4px;
    // If table rows are given explicit widths, and there is not enough room, the hover tab is deleted because it does
    // not have sufficient content to keep its place. In such cases, make an internal child and give it an explicit
    // width too.
    .hover-tab-hack {
      width: rem-calc(6);
    }
  }

  .no-data-text {
    text-align: center;
    &:hover {
      background-color: $content-bg-color;
    }
    padding-top: rem-calc(16);
    padding-bottom: rem-calc(16);
  }

  .small-cell {
    width: 10em;
  }

  // TODO switch tables over to using this class instead of copying this property around
  &.full-width {
    width: 100%;
  }

  tr:hover, .selected-row {
    background-color: $body-bg-color;

    // Only apply the hover effect to direct children of the hovered row. This way, if you have a child table within
    // a table, the child will not have the hover-tab present when the parent is hovered over.
    >td.hover-tab {
      background-color: $main-accent-color;

      // The following block makes the left blue border on a table cover the table borders as well.
      // Ordinarily we could just change the border color too, but we are using border-collapse: collapse which means
      // that either the top or bottom border actually belongs to the adjacent tr and doesn't change color.
      // This is a hack I found in the comments here: https://css-tricks.com/almanac/properties/b/border-collapse/
      & {
        position: relative;

        // Use both before and after in case this tr owns either the top or bottom border
        &:before {
          content: "";
          position: absolute;
          left: 0px;
          width: rem_calc(6);
          top: -1px;
        }
        &:after {
          content: "";
          position: absolute;
          left: 0px;
          width: rem_calc(6);
          bottom: -1px;
        }
      }
    }
  }

  // Turn off the hover effect on some rows. For search and triggers, you can expand a row to include another row
  // underneath. Use no-hover to remove the hover effect from those rows.
  tr.no-hover:hover {
    background-color: $content-bg-color;
  }

  .timestamp-cell {
    width: rem-calc(140);

    .interval-container {
      display: inline-block;
    }
  }

  .icon-cell {
    width: rem-calc(40);
    &.expander {
      font-size: rem-calc(20);
    }
  }
  .right-dropdown {
    width: rem-calc(16);
  }

  tbody tr.no-bottom-border td {
    border-bottom: 1px solid $content-bg-color;
  }

  tbody tr.no-top-border td {
    border-top: 1px solid $content-bg-color;
  }
}

//TODO: When we want to re-use this class, refactor to compute widths
.fixed-size-table {
  border-spacing: 0;
  width: rem-calc(766);

  tbody, tbody tr, thead { display: block; }

  tbody {
    max-height: rem-calc(175);
    overflow-y: auto;
    tr td {
      white-space: normal;
    }
  }
}

.long-cell {
  width: rem-calc(400);
}

.medium-cell {
  width: rem-calc(250);
}

.short-cell {
  width: rem-calc(110);
}

.return-to-top-footer {
  background-color: $body-border-color;

  td {
    text-align: center;
    padding: 0;
    display: block;
    &:hover {
      background-color: $body-border-color;
    }
  }

  .footer-text {
    padding: rem-calc(3);
    font-size: rem-calc(12);
    font-weight: $font-weight-demibold;

    &:hover {
      color: $main-accent-color;
    }
  }
}

///
/// Tabs
///

// TODO all of these tabs options are very fiddly and not very streamlined. Take a look at them all
// and try to consolidate in order to require the programmer to read less CSS to make a tab box that looks ok.
.tabs {
  dd, .tab-title {
    > a {
      padding: $tabs-navigation-padding $column-gutter;
    }
  }
  //noinspection CssOptimizeSimilarProperties
  .active {
    border-bottom: $border-rule;
    border-bottom-width: 3px; // just redefine stroke
    cursor: default;
  }
}

dl.tabs {
  border-bottom: $border-rule;
  dd {
    padding: $column-gutter $column-gutter 0;
    cursor: pointer;

    &:first-child {
      margin-left: $column-gutter;
    }
  }
}

.tabs-content {
  padding-top: $column-gutter;
}

///
/// Forms
///

fieldset {
  padding: .7em;
  margin: .7em 0;

  label {
    display: inline;
  }
}

input[type=text], input[type=email], input[type=number], input[type=password] {
  margin: 0;
  padding: 0;
  height: 28px;
  border: none;
  border-bottom: 1px solid $input-border-color;
  color: $body-font-color;
  box-shadow: none;
  font-size: 14px;
  line-height: 16px;
  font-weight: $font-weight-demibold;
}

input[type=text], input[type=email], input[type=number], input[type=password], textarea {
  @include input-placeholder { // styles placeholder text
    color: $input-placeholder-font-color;
    font-weight: $font-weight-normal;
  }

  &:hover:not(:disabled) {
    background: $ds-blue-1;
  }

  &:focus {
    background: $ds-blue-1;
    border-color: $ds-blue-6;
  }

  &[disabled], &[readonly] {
    background: $gray-3;
  }
}

fieldset[disabled] {
  input[type=text], input[type=email], input[type=number], input[type=password], textarea {
    background: $gray-3;
  }
}

input[type="checkbox"],
input[type="file"],
input[type="radio"] {
  margin: 8px;
  display: inline;
}

textarea {
  margin: 4px 0 0 0;
  font-weight: $font-weight-demibold;
  box-shadow: none;
  border-radius: 2px;
}

.toastui-editor-mode-switch {
  display: none !important;
}

// turn off number-input spinners
// TODO: invent a spinner that doesn't look bad
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance:textfield;
  text-align: center;
}

.ng-invalid.ng-touched:not(:focus):not(form):not(.dont-add-error-border) {
  border-bottom: 1px solid $alert-color;

  &:hover {
    background: $cinnabar-red-1;
  }
}

.form-error-text {
  color: $alert-color;
  text-align: end;
}

.form-error-text-start-align {
  color: $alert-color;
  text-align: start;
}

.disabled-text {
  color: $inactive-font-color;
}

.nice-form {
  $half-width: rem_calc(300);

  label:not(.for-checkbox):not(.date-range-error) {
    margin: rem-calc(20) 0 0 0;
    padding: 0;
    border: none;
    font-size: rem-calc(12);
    line-height: 150%;
    font-weight: $font-weight-normal;
    color: $gray-7;
    div .title-header {
      margin: 0;
    }

    .input-caption {
      margin-top: 4px;
      line-height: 16px;
      font-size: 12px;
    }
  }

  select:not(.inline-block) {
    display: block;
  }

  .error-message {
    color: $invalid-text-color;
    font-size: rem-calc(12);
  }

  .top-padded {
    padding-top: rem-calc(20);
  }

  .no-margin-top {
    margin-top: 0;
  }

  .half-width {
    width: $half-width;
  }

  .removable-item-list {
    margin: 0;
    .removable-item {
      padding: rem-calc(5);
      border: rem-calc(2) solid $content-bg-color;
      font-size: rem-calc(14);
      font-weight: $font-weight-demibold;
      display: flex;
      justify-content: space-between;
      align-items: center; // vertical align flex items
      &:hover {
        background-color: $body-bg-color;
      }
      .delete-button {
        padding: rem-calc(2) rem-calc(4) 0 rem-calc(4);
      }
      .removable-item-large {
        font-size: larger;
      }
    }
  }

  input[type=checkbox] {
    margin: 0 rem-calc(1) 0 0;
  }

  // TODO: Eventually remove this styling bc <select> should not be used
  select {
    width: $half-width;
    height: rem-calc(28);
    border: none;
    border-bottom: 1px solid $input-border-color;
    margin: 0;
    padding: rem-calc(2) rem-calc(16) 0 0;
    font-size: rem-calc(14);
    font-weight: $font-weight-demibold;
    color: $body-font-color;
    background-color: $content-bg-color;
    background-position: right rem-calc(-5) center;
    &.value-inactive {
      font-weight: $font-weight-normal;
      color: $input-placeholder-font-color;
    }
  }

  ds-select {
    width: $half-width;

    &.inline-block {
      display: inline-block;

      ds-single-select, ds-multi-select {
        width: 100%;
      }
    }
  }

  select:disabled {
    border: $border-rule;
    background-color: $gray-5;
    cursor: default;
  }

  policy-selector {
    select:nth-of-type(1) {
      margin-bottom: rem-calc(8);
    }
  }

  .tiny-input {
    width: 2rem;
    display: inline-block;
  }

  .short-input {
    width: 96px;
    display: inline-block;
  }

  .medium-input {
    width: 10rem;
    display: inline-block;
  }

  interval-selector {
    input[type=number] {
      @extend .short-input;
    }

    ds-select .ds-dropdown-trigger {
      margin-top: 0;
    }
  }

  &.panel { // panel makes a grey background, input looks bad with white background and no border on grey
    input[type=text], input[type=email], input[type=number] {
      padding-left: rem-calc(8);
      border: 1px solid $input-border-color;
    }
  }

  .nice-form-display-text {
    font-size: rem-calc(14);
    font-weight: $font-weight-demibold;
  }

  .roles-bottom-padded {
    padding-bottom: rem-calc(80);
  }

  .no-margin-radio-button {
    input[type="radio"] {
      margin: 0;
    }
  }
}

///
/// Links
///

a {
  color: $body-font-color;
}

.link-text, ng1-a-link.link-text a {
  color: $link-color;
  font-weight: $font-weight-demibold;
  &:hover:not(.disabled-link) {
    color: $hover-color;
    text-decoration: none;
  }

  &.disabled-link {
    color: $inactive-font-color;
    pointer-events: none;
  }
}

.link-on-hover-text {
  font-weight: $font-weight-demibold;
  &:hover {
    color: $link-color;
  }
}

///
/// Icons
///

i {
  // Iconicons icons are tiny when put in tables. Use this to make them a reasonable size so they
  // fit in with font-awesome icons.
  &.embiggen {
    font-size: 1.2rem;
  }

  $darken-percentage: 10%;
  transition: color 300ms ease-out 0s;
  &.primary, &.dynamic-thresholds {
    color: $primary-color;
    &:hover {
      color: darken($primary-color, $darken-percentage);
    }
  }

  &.secondary {
    color: $secondary-color;
    &:hover {
      color: darken($secondary-color, $darken-percentage);
    }
  }

  &.success {
    color: $success-color;
    &:hover {
      color: darken($success-color, $darken-percentage);
    }
  }

  &.upper-threshold {
    color: $trigger-upper-threshold-color;
  }

  &.warning {
    color: $warning-color;
    &:hover {
      color: darken($warning-color, $darken-percentage);
    }
  }

  &.lower-threshold {
    color: $warning-color;
  }

  &.every-event {
    color: $primary-color;
  }

  &.sequence-trigger {
    color: $primary-color;
  }

  &.uba-trigger {
    color: $primary-color;
  }

  &.info {
    color: $info-color;
    &:hover {
      color: darken($info-color, $darken-percentage);
    }
  }

  &.alert {
    color: $alert-color;
    &:hover {
      color: darken($alert-color, $darken-percentage);
    }
  }

  &.inactive {
    color: $inactive-font-color;
  }
  &.large {
    font-size: rem-calc(45);
  }
}

///
/// File Upload
///
.filedrop {
  width: 100%;
  height: rem-calc(65);
  border: dashed rem-calc(1) $input-border-color;
  border-radius: rem-calc(5);
  display: flex;
  align-items: center;
  justify-content: center;
  color: $input-border-color;
}

input[type="file"].hidden-file-input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: rem-calc(65); //same height as filedrop
  left: 0;
  margin: 0;

  &:hover + .filedrop {
    background-color: $body-bg-color;
  }
}

///
/// Callout Boxes: http://zurb.com/building-blocks/bordered-alerts
///

.alert-box {
  background-color: $content-bg-color;
  box-shadow: $item-box-shadow;
  color: $body-font-color;
  border: none;
  border-left: 6px solid $primary-color;

  //use flexbox to vertically center  both the icon and the text
  display: flex;
  align-items: center;
  justify-content: flex-start;

  // foundation padding is 14px, but there is 5px extra space under the items in the alert box.
  // I think this is due to the font we are using. Remove 5px of padding from the bottom as a hack,
  // try to find a better way.
  padding-bottom: 9px;

  .main-icon {
    margin-right: 5px;
  }

  &.success {
    background: $content-bg-color;
    color: $body-font-color;
  }

  &.alert {
    background: $content-bg-color;
    color: $body-font-color;
  }

  &.warning {
    background: $content-bg-color;
    color: $body-font-color;
  }

  &.info {
    background: $content-bg-color;
    color: $body-font-color;
  }

  &.small {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    font-size: 0.75rem;

    .close {
      font-size: 1.5rem;
    }
  }

  &.large {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    font-size: 2rem;

    .close {
      font-size: 3rem;
    }
  }

  .close {
    font-size: 2rem;
    line-height: 1.25rem;
  }

  .flex-contents {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  >.flex-grow {
    flex-grow: 1;
  }

  &.fixed {
    position: fixed;
    top: rem-calc(100);
    left: 25%;
    width: 50%;
  }

  .indent {
    margin-left: rem-calc(15);
  }
}

///
/// Buttons
///

button, .button {
  // adjust the bottom padding on buttons because the AvenirNext font has a large amount of extra space
  // underneath it.
  padding-bottom: .4rem;
  font-weight: $font-weight-demibold;
}

button.inactive, .button.inactive {
  background: $info-color;
}

button.icon-only, .button.icon-only {
  width: rem-calc(44);
  height: rem-calc(36);

  font-size: rem-calc(28);
  line-height: 100%;
  text-align: center;
  padding: 0;
  padding-top: .3rem;
  vertical-align: top;
}

button.icon-and-text, .button.icon-and-text {
  height: rem-calc(36);
  line-height: 100%;
  vertical-align: top;
}

button.clone, .button.clone {
  float:left;
  margin-left: $column-gutter;
}

button.btn-light, a.btn-light {
  color: $primary-color;
  background: $gray-1;
  border: 1px solid $primary-color;
  &.disabled {
    border: $border-rule;
    color: $primary-color;
    cursor: default;
    background: $gray-1;
  }
  &:hover {
    color: $primary-color;
    background: $gray-1;
  }
  &:not(.disabled) {
    &:hover {
      background: scale-color($ds-blue-6, $lightness: 90%);
    }
  }
}

button.link, .button.link {
  background-color: transparent !important;

  color: $primary-color;
  font-weight: $font-weight-demibold;

  &.secondary {
    color: $secondary-color;
  }

  &.success {
    color: $success-color;
  }

  &.warning {
    color: $warning-color;
  }

  &.info {
    color: $info-color
  }

  &.alert {
    color: $alert-color;
  }

  &:hover {
    background-color: transparent;
    text-decoration: underline;
  }

  &.borderless:hover {
    text-decoration: none;
  }
}

.action.buttons {
  text-align: right;

  button.alert, .button.alert {
    float: left;
  }
}

.pr-button-group {
  display: inline;
  margin: 0 0 0 rem-calc(1);
  list-style-type: none;
  font-size: 0;
  white-space: nowrap;

  li {
    margin-left: rem-calc(-1);
  }
}

.pr-box {
  display: inline-block;
  z-index: z-index(dropdown);
  padding: rem-calc(6) rem-calc(5) rem-calc(4) rem-calc(5);
  border: 1px solid $gray-7;
  line-height: 100%;
  font-weight: $font-weight-demibold;
  font-size: rem-calc(13);
  background-color: $content-bg-color;
}

.pr-button {
  @extend .pr-box;
  &:not(.disabled) {
    &:hover, &.active {
      background-color: $gray-7;
      color: $light-text-for-dark-background;
      cursor: pointer;
    }
  }
  &.disabled {
    border: $border-rule;
    color: $gray-5;
    cursor: default;
  }

  &.warning {
    background-color: $warning-color;
    color: $light-text-for-dark-background;
  }

  // icons should not add to the line height
  i {
    line-height: 0;
  }
}

.range-pr-button {
  @extend .pr-button;
  font-size: rem-calc(12);
  border: 1px solid $gray-5;
  padding: rem-calc(6) rem-calc(12) rem-calc(4) rem-calc(12);
  margin-left: -0.075rem;
  height: rem-calc(25);

  &:not(.disabled) {
    &:hover, &.active {
      background-color: $main-accent-color;
    }
  }
}

.color-pr-button {
  @extend .pr-button;
  &:not(.disabled) {
    &:hover, &.active {
      background-color: $main-accent-color;
    }
  }
}

//
// Pagination
//
pagination-detail {
  line-height: 100%;
  text-align: center;

  ul.pagination {
    display: inline-block;
    margin: rem-calc(8) 0 rem-calc(4) 0;
  }
}

.pr-dropdown-button {
  display: flex;
  align-items: center;
  padding-top: 6px;
  border-bottom: 4px solid transparent;
  color: $body-font-color;

  &:hover, &.active, &:focus {
    &:not(.button-dropdown, .link-dropdown) {
      border-bottom: 4px solid $main-accent-color;
    }
  }

  &.link-dropdown {
    i.dropdown {
      font-size: 0.5rem;
    }

    &:hover {
      color: $main-accent-color;
    }

    &.active, &:focus {
      color: $ds-blue-8;
    }
  }
}

pr-dropdown {
  display: inline-block;
  font-size: 14px;

  // [pr-dropdown-body] uses position:absolute, and when it is visible it should scroll with this element,
  // so give pr-dropdown position:relative to facilitate that
  position: relative;

  .pr-dropdown-flex-row {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    .fill-available-space {
      flex-grow: 1;
    }
  }

  .dropdown-label {
    padding-right: 2px;
    color: $gray-7;
  }

  &.with-button {
    margin-left: 0;
    *[pr-dropdown-trigger] {
      padding-left: 0;
      border-bottom: 4px solid transparent;
    }
  }

  *[pr-dropdown-trigger] {
    @extend .pr-dropdown-button;
    cursor: pointer;

    &.inline {
      display: inline;
      padding: 0;
    }

    &.disabled-trigger {
      pointer-events: none;

      .trigger-container {
        color: $inactive-font-color;
        background-color: $gray-2;
        border-bottom-color: $gray-6;
      }
    }

    .dropdown-value {
      font-weight: $font-weight-demibold;
    }
  }

  *[pr-dropdown-body] {
    z-index: z-index(dropdown);
    position: absolute;
  }

  ul[pr-dropdown-body] {
    text-align: left;
    margin-top: -6px;
    margin-left: 0;
    border: none;
    border-top: 4px solid $main-accent-color;
    box-shadow: $menu-box-shadow;
    background-color: $content-bg-color;
    color: $body-font-color;
    font-size: 14px;
    list-style-type: none;
    max-height: 300px;
    overflow-y: auto;

    li:not(.remove-custom-styles) {
      padding: 0 16px;
      line-height: 1.75rem;
      white-space: nowrap;

      &.threatmatch-li {
        padding: 0;
      }
      &:hover, &:focus {
        outline: none;
        background-color: $ds-blue-2;
        cursor: pointer;
      }
      &.active {
        background-color: $gray-4;
        i {
          color: $main-accent-color;

          &.fa-house {
            color: $gray-7;
          }
        }
      }
      &.no-hover {
        background-color: inherit;
        cursor: default;
      }
      a {
        color: inherit;
        &:hover {
          text-decoration: none;
        }
      }
      i {
        font-size: 14px;
        color: transparent;
      }
      i:not(.fa-check) {
        padding-right: 4px;
      }
      .fa {
        padding-top: 4px;
      }
      .visible-icon {
        color: inherit !important;
      }
      .visible-alert {
        color: $alert-color !important;
      }
      .visible-edit {
        color: $ds-blue-6 !important;
      }
      .search-button {
        color: $primary-color;
        background-color: transparent;
        position: absolute;
        right: 7px;
        top: 4px;
      }
    }
  }
}

predicate-dropdown-menu, search-events-per-page-dropdown {
  pr-dropdown li {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
}

.radio-list {
  font-size: rem-calc(14);
  list-style: none;
  margin: 0;
}

#date-range-controls {
  a#clear-dates.pr-button {
    position: absolute;
    bottom: $column-gutter*0.5;
    left: $column-gutter*0.5;
  }
}

person-picker {
  ds-dropdown .options-container {
    max-height: 290px !important;
    overflow: revert;
  }
}

.saved-query-name {
  display: inline-block;
  font-size: 12px;
  font-weight: $font-weight-normal;
  color: $gray-7;
  line-height: 16px;
}

.saved-query-aggregation {
  font-size: 12px;
  font-weight: $font-weight-normal;
  color: $gray-7;
  line-height: 16px;
  margin-top: 2px;

  .aggregation-field {
    font-weight: $font-weight-bold;
  }
}

.saved-query-string {
  display: block;
  font-size: 14px;
  color: $gray-9;
  font-weight: $font-weight-demibold;
  line-height: 16px;
}

.saved-query-actions {
  display: block;
  margin-top: 8px;
}

search-link, *[search-link] {
  a[href]:hover {
    text-decoration: none;
  }

  &.saved-query-string {
    a:hover, span:hover {
      color: $ds-blue-7;
    }
  }

  a:not([href]) {
    cursor: default;
  }
}

.f-dropdown {
  max-width: 300px;
  width: auto;

  li a {
    overflow: hidden;
    text-overflow: ellipsis;
  }

}

a.hover-link:hover {
  text-decoration: underline;
}

.boxed-badge {
  display: inline-block;
  padding: rem-calc(4) rem-calc(4) rem-calc(2); //Less space on the bottom because we don't have any decenders
  font-size: 0.75rem;
  line-height: 1;
  margin: 0 $column-gutter*0.25;
  border-radius: rem-calc(4);
  border: rem-calc(1) solid $body-font-color;
}

.rated-threat, .unrated-threat, .threat-badge, .severity-badge {
  @extend .boxed-badge;

  &.rating-CRITICAL {
    border-color: $severity-critical-color;
    background-color: $severity-critical-color;
    color: $light-text-for-dark-background;
  }

  &.rating-HIGH {
    border-color: $severity-high-color;
    background-color: $severity-high-color;
    color: $light-text-for-dark-background;
  }
  &.rating-MEDIUM {
    border-color: $severity-medium-color;
    background-color: $severity-medium-color;
    color: $light-text-for-dark-background;
  }
  &.rating-LOW {
    border-color: $severity-low-color;
    background-color: $severity-low-color;
    color: $light-text-for-dark-background;
  }
}

.notified-regulator-badge {
  @extend .boxed-badge;
  margin: 0;
  border-color: $gray-6;
  background-color: $gray-1;
}

.alpha-badge {
  @extend .boxed-badge;
  color: $cinnabar-red-6;
  border-color: $cinnabar-red-8;
  background-color: white;
}

.beta-badge {
  @extend .boxed-badge;
  color: $success-color;
  border-color: $success-color;
  background-color: white;
  font-size: x-small;
}


threat-rating-dropdown {
  .rated-threat, .unrated-threat, .threat-badge {
    cursor: pointer;
  }

  li.indent {
    padding-left: $column-gutter;
  }
}

.trigger-graph-container {
  margin: rem-calc(15) rem-calc(15) 0 rem-calc(15);
  text-align: center;

  #legend {
    display: inline-block;
    margin-top: rem-calc(15);
    width: 100%;
    font-size: rem-calc(14);

    #trendline {
      color: $trendline-color;
    }
  }

  .normal-alert {
    fill: $event-color;
    stroke: $event-color;
    color: $event-color;
  }
  .upper-alert {
    fill: $trigger-upper-threshold-color;
    stroke: $trigger-upper-threshold-color;
    color: $trigger-upper-threshold-color;
  }
  .lower-alert {
    fill: $trigger-lower-threshold-color;
    stroke: $trigger-lower-threshold-color;
    color: $trigger-lower-threshold-color;
  }

  .selected-alert {
    fill-opacity: .2;
  }
}

.sequence-alert-container {
  margin: rem-calc(15) rem-calc(15) 0 rem-calc(15);
  text-align: center;
}

.datepicker {
  &.datepicker-inline {
    position: relative;
  }

  table {
    table-layout: auto;
    width: auto;
    border-collapse: separate;
    border-spacing: 0;

    td, th {
      background-color: $input-bg-color;
      color: $input-font-color;
    }
  }

  &.datepicker-dropdown.dropdown-menu {
    padding: 2px;
    background-color: $input-border-color;
  }

  .month.disabled, .day.disabled {
    color: $gray-5;
  }
}

.query-string {
  color: rgba($body-font-color, 0.6);
  font-family: $code-font-family;
  font-size: 1.1em;
}

severity-icon {
  .severity-critical {
    color: $severity-critical-color;
  }
  .severity-high {
    color: $severity-high-color;
  }
  .severity-medium {
    color: $severity-medium-color;
  }
  .severity-low {
    color: $severity-low-color;
  }
}

floating-tooltip {
  background: $floating-tooltip-background; //crgba($body-font-color, 0.85);
  color: $body-border-color;
  font-weight: 100;
  //border: 1px solid $body-bg-color;
  z-index: z-index(tooltip);
  position:fixed;
  display:block;
  padding: 7px 12px 5px 12px;
  height: auto;
  width: auto;
  max-width: rem-calc(500);
  box-shadow: 2px 1px 3px 0px $gray-9;
}

floating-tooltip div {
  font-size: rem-calc(12);
  line-height: 100%;
}

floating-tooltip div span {
  font-weight: $font-weight-demibold;
}

floating-tooltip:after {
  content: "";
  position: absolute;
  border-style: solid;
  display: block;
  width: 0;
}
// These :after 's are for creating the speech-bubble-like arrow hanging off tooltips
floating-tooltip.top-left-arrow:after {
  bottom: -10px;
  right: 4px;
  border-width: 10px 10px 0;
  border-color: $floating-tooltip-background transparent transparent transparent;
}

floating-tooltip.top-right-arrow:after {
  bottom: -10px;
  left: 3px;
  border-width: 10px 10px 0;
  border-color: $floating-tooltip-background transparent transparent transparent;
}

floating-tooltip.bottom-left-arrow:after {
  top: -10px;
  right: 4px;
  border-width: 0px 10px 10px;
  border-color: transparent transparent $floating-tooltip-background transparent;
}

floating-tooltip.bottom-right-arrow:after {
  top: -10px;
  left: 3px;
  border-width: 0px 10px 10px;
  border-color: transparent transparent $floating-tooltip-background transparent;
}

floating-tooltip.mid-left-arrow:after {
  right: -20px;
  top: 0px;
  border-width: 10px 10px 10px;
  border-color: transparent transparent transparent $floating-tooltip-background;
}

floating-tooltip.mid-right-arrow:after {
  left: -20px;
  top: 0px;
  border-width: 10px 10px 10px;
  border-color: transparent $floating-tooltip-background transparent transparent;
}

.applied-label {
  background-color: $gray-5;
  color: $body-font-color;
  border-radius: rem-calc(4);
  padding: rem-calc(4) rem-calc(5) rem-calc(2) rem-calc(5);
  margin-right: rem-calc(2);
  font-weight: $font-weight-demibold;
}

.inline-block {
  display: inline-block;
}

.display-block {
  display: block;
}

formatted-html, div.formatted-html {
  word-wrap: break-word;

  > span * {
    font-size: 14px;
  }

  > span > *, > span > ol li {
    margin-bottom: 14px;
  }

  > span > *:last-child {
    margin-bottom: 0;
  }

  h1, h2, h3, h4, h5, h6 {
    border: none;
    margin-bottom: 0;
  }
  h1 {
    font-size: $h2-font-size;
  }
  h2 {
    font-size: $h3-font-size;
  }
  h3 {
    font-size: $h4-font-size;
  }
  h4 {
    font-size: $h5-font-size;
  }
  h5 {
    font-size: $h6-font-size;
  }
  pre {
    margin-left: 1rem;
    white-space: pre-wrap;

    ip-dropdown {
      ul {
        color: $body-font-color;
        font-family: $body-font-family;
      }
    }
    .f-dropdown li a {
      display: inline;
    }
  }
  ul, ol {
    font-size: 100%;
    margin-bottom: 0;
  }
  p {
    font-weight: inherit;
    font-size: inherit;
  }
}


// deprecated
.reveal-modal {
  padding: rem-calc(20);

  max-width: none;
  max-height: 80%;
  overflow: auto;

  &.small-modal {
    width: 25%;
  }

  &.medium-modal {
    width: 50%;
  }

  &.large-modal {
    width: 95%; //TODO: argue daniel down to 75%
  }
}

.tt-menu {
  background-color: $body-bg-color;
  box-shadow: $item-box-shadow;
  padding: $column-gutter*0.5;
}

.tt-suggestion.tt-cursor {
  background-color: adjust_color($body-bg-color, $lightness: 30%);
  color: $body-font-color;
}

.loading-spinner {
  opacity: 0.6;
  margin: 7rem 0;
}

.loading-container {
  text-align: center;
}

*[transparent-loadable] {
  position: relative;

  .transparent-loading-container {
    position: absolute;
    z-index: z-index(content);
    background-color: $body-bg-color;
    text-align: center;
    opacity: 0.6;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.scrolling-panel {
  overflow-y: scroll;

  @media print {
    overflow-y: inherit;
  }
}

.page-break-after {
  @media print {
    page-break-after: always;
  }
}

//modal

.modal {
  h3 {
    font-weight: $font-weight-bold;
  }
  .center-text {
    margin: 0;
    padding: 2rem;
    text-align: center;
  }
}

trigger-graph {
  height: 175px;

  svg {
    fill: none;

    .label {
      fill: $body-font-color;
      text-anchor: middle;
      font-size: rem-calc(13);
    }

    .axis {
      stroke: $gray-2;
      stroke-width: 1;
      stroke-opacity: .5;
    }

    .lines path {
      &.range-line {
        stroke-width: rem-calc(2);
        stroke: $event-color;
      }
    }

    .anomaly-circle {
      stroke: $alert-color;
      stroke-dasharray: 3, 2;
    }

    .trend-line {
      fill: none;
      stroke: $trendline-color;
      opacity: 0.9;
      stroke-width: rem-calc(2);
      stroke-dasharray: 2, 1;
    }

    .horizontal-line-upper {
      fill: none;
      opacity: 0.9;
      stroke-width: rem-calc(2);
      stroke: $trigger-upper-threshold-color;
    }

    .horizontal-line-lower {
      @extend .horizontal-line-upper;
      stroke: $trigger-lower-threshold-color;
    }
  }
}

.declutter {
  .show-on-hover {
    opacity: 0;
    &:focus {
      opacity: inherit;
    }
  }

  &:hover {
    .show-on-hover {
      opacity: inherit;
    }
  }
}

.no-list-styles {
  display: block;
  margin: 0;
  list-style: none outside;
}

.margin-top {
  margin-top: rem-calc(5);
}

// Side navigation bar like the one used in alert-inbox and FFIEC CAT policies
.sheet-side-menu-section {
  position: absolute;
  width: 128px;
  padding-right: 0;
  padding-left: 8px;

  h2 {
    padding: 0;
  }

  .sheet-side-menu {
    padding-top: 4px;

    .sheet-side-menu-item {
      display: inline-block;
      font-size: 0.875rem;
      font-weight: $font-weight-demibold;
      padding: 10px 0 10px 12px;
      width: 100%;

      &.active, &:hover {
        background-color: $gray-4;
      }

      .sheet-side-menu-item-text {
        &:hover {
          color: inherit;
          background-color: inherit;
        }
      }

      &.alert-inbox-filter {
        padding-left: 8px;
        font-size: 0.75rem;
      }
    }
  }
}

.banded-block {
  color: $sub-header;
  padding: rem-calc(8) rem-calc(8) 0 rem-calc(8);
  border-left: 3px solid $gray-2;
  margin: rem-calc(8) 0;
}

.banded-block-title {
  border-bottom: 1px solid $gray-2;
  font-weight: $font-weight-bold;
  font-size: rem-calc(12);
  margin-left: rem-calc(10);
}

.banded-block-content {
  padding-left: rem-calc(16);
}

.left-border.success {
  position: absolute;
  height: 100%;
  left: 0px;
  width: 3px;
  background-color: $success-color;
}

%centered-notification {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.error-notification {
  @extend %centered-notification;
  color: $alert-color;
}
.gray-notification {
  @extend %centered-notification;
  color: $gray-6;
}

///
/// Drag & Drop
///

.drag-over-border {
  border: rem-calc(2) dashed $ds-blue-6 !important;
}

.drag-opaque {
  opacity: 0.4;
}

.drag-cursor {
  cursor: move;
}


///
///  Media Queries
///

.media-chart-heights {
  @media (max-height: $media-huge) { height: 800px }
  @media (max-height: $media-large) { height: 500px }
  @media (max-height: $media-medium) { height: 400px }
  @media (max-height: $media-small)  { height: 300px }
  @media (max-height: $media-extra-small)  { height: 200px }
  @media print { height: 300px; }
}

.underline {
  border-bottom: $border-rule;
}

.uppercase {
  text-transform: uppercase;
}

.demibold {
  font-weight: $font-weight-demibold;
}

.small-font {
  font-size: 0.75rem;
}

.short-width {
  width: 20%;
}

.long-width {
  width: 80%;
}

.extra-long-width {
  width: 90%;
}
.space-between {
  justify-content: space-between;
}

.notification-message {
  display: flex;
  font-size: rem-calc(13);
  align-items: center;

  notification-icon i {
    margin-right: 16px;
  }

  button.notification-message-action {
    font-size: rem-calc(13);
  }
}

.indicator {
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  padding-left: 2px;
}

// Only affects this Ng2 component when it's used from Ng2
pr-dropdown:not([ng-version]) {
  .dropdown-label {
    padding-right: 6px;
  }
  .dropdown-value {
    padding-right: 3px;
  }
  ul[pr-dropdown-body] {
    li {
      &.threatmatch-li {
        padding: 0;
      }

      &.title-option {
        font-size: 0.75rem;
        color: $gray-6;
        text-transform: uppercase;
      }

      &.separator-above {
        border-top: 1px solid $gray-5;
        padding-top: 0.1875rem;
      }
      .wide {
        display: block;
        padding: rem-calc(1) rem-calc(30) 0 rem-calc(10);
      }
    }
  }
}

.sidebar {
  .row-flex {
    display: flex;
    flex-direction: row;

    dt, dd {
      padding: rem-calc(4) 0;
      line-height: rem-calc(24);
      font-size: rem-calc(14);
    }

    dt {
      flex: 35%;
      font-weight: $font-weight-demibold;
      color: $sub-header;
    }

    dd {
      flex: 65%;
      padding-right: rem-calc(16);
      font-weight: $font-weight-normal;
      color: $body-font-color;
    }
  }
}

escalate-dropdown {
  .border-top {
    border-top: $border-rule;
    padding-top: rem-calc(3);
  }
}

search-results-table.columns {
  padding: 0;
}

ticket-alerts-table search-results-table {
  overflow-x: unset;
}

saved-query-summary {
  .action-icon .mat-icon {
    fill-rule: evenodd;
    fill: $severity-medium-color;
  }
}

info-banner {
  .banner-container {
    height: 85px;
    border-top: 4px solid;
    display: flex;
    align-items: center;
    flex-grow: 1;
    font-size: 14px;

    &.banner-type-success {
      border-top-color: $grass-green-6;
      background-color: $grass-green-1;
    }

    &.banner-type-info {
      border-top-color: $ds-blue-6;
      background-color: $ds-blue-1;
    }

    &.banner-type-warning {
      border-top-color: $autumn-orange-6;
      background-color: $autumn-orange-1;
    }

    &.banner-type-alert {
      border-top-color: $cinnabar-red-6;
      background-color: $cinnabar-red-1;
    }
  }

  .banner-inner-container {
    display: flex;
    flex-grow: 1;
    padding: 24px;
  }

  .banner-main-icon {
    padding: 0 10px 0 10px;
    font-size: 24px;
    font-weight: bold;

    &.banner-type-success {
      color: $grass-green-6;
    }

    &.banner-type-info {
      color: $ds-blue-6;
    }

    &.banner-type-warning {
      color: $autumn-orange-6;
    }

    &.banner-type-alert {
      color: $cinnabar-red-6;
    }
  }

  .link-text {
    &.banner-type-success {
      color: $grass-green-6;
    }

    &.banner-type-info {
      color: $ds-blue-6;
    }

    &.banner-type-warning {
      color: $autumn-orange-6;
    }

    &.banner-type-alert {
      color: $cinnabar-red-5;
    }
  }

  .banner-close-icon {
    color: $gray-6;
  }

  .banner-message-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }

  .banner-title {
    font-weight: 600;
    font-size: 16px;
  }

  .banner-message {
    font-weight: 500;
    font-size: 14px;
    white-space: pre-line
  }
}

// Styles for the new (2021) page layouts
.edit-page, .view-page {
  $select-width: rem-calc(300) + rem-calc(16); // .nice-form select width + padding for arrow
  $section-container-padding: rem-calc(25); // .section-container padding
  // where the heck does rem-calc(10) come from?
  $left-content-width: $select-width + 2 * $section-container-padding + rem-calc(10);
  $section-margin: 12px;

  header {
    &.action-header.action-group:not(.remove-old-styles) {
      justify-content: left;
      align-items: center;

      &.action-column {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .header-action-buttons {
      margin-left: auto;
    }
  }

  .section {
    background: white;
    &:not(.pr-dropdown-overflow-visible) {
      overflow: hidden;
    }
    margin: $section-margin 0;
    padding-bottom: 24px;

    &.overflow-visible {
      overflow: visible;

      // because overflow: visible causes margin collapse
      .sub-header {
        padding-top: 16px;
      }
    }

    &:first-of-type {
      margin-top: 0;
    }
  }

  .fixed-right-content {
    position: static;
    float: right;
    width: 25%;
    min-width: $left-content-width;
    overflow-y: auto;
    height: 100%;
    padding-left: $column-gutter;
  }

  .fixed-left-content {
    position: static;
    float: right;
    width: 75%;
    max-width: calc(100% - #{$left-content-width});
    overflow-y: auto;
    height: 100%;
  }
}

.view-page {
  .sidebar .row-flex dt {
    font-weight: normal;
  }

  .boxed-badge {
    border: 1px solid $table-header-bg-color;
  }

  .section-title {
    font-size: 0.75rem;
    color: $sub-header;
  }
}

trigger-action-display #watchers-list li.nice-form-display-text {
  font-size: 0.875rem;
}

trigger-edit-page .ticket-watchers-title {
  font-size: 0.75rem;
  color: $sub-header;
  margin-top: 1rem;
}

.required-asterisk {
  color: $alert-color;
}

tag-search-multiselect-dropdown, savable-multiselect-dropdown {
  margin-left: 4px;
  margin-right: 4px;

  .dropdown-label {
    color: $gray-6;
    font-weight: $font-weight-normal;
  }

  ds-dropdown {
    height: 34px;
    display: inline-block;
    border-bottom: 4px solid transparent;

    .fa-chevron-up {
      font-size: 0.5rem;
    }

    .fa-chevron-down {
      font-size: 0.5rem;
    }

    .trigger-container {
      display: flex;
      height: 24px;
      border-bottom: 1px solid transparent;

      &:active, &:focus {
        border-bottom: 1px solid $main-accent-color;
      }
    }
  }

  &.dropdown-menu ds-dropdown {
    *[pr-dropdown-trigger] {
      @extend .link-dropdown;
    }

    .trigger-container {
      &:active, &:focus {
        border-bottom: 1px solid transparent;
      }
    }
  }

  action-buttons div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

view-data-restores-modal {
  #status-selector {
    .ds-dropdown-trigger {
      border-bottom: none;
      margin: 0;
    }

    ds-dropdown .trigger-container {
      border: none;
    }
  }
}

activity-log-section #visible-activity-logs .trigger-container {
  border-bottom: none;
}

search-nested-fields .ds-dropdown-trigger {
  margin: 0;
}

ds-page-header ds-actions {
  display: flex;
  gap: 8px;
}

// This is temporary CSS so I don't need to migrate at once all pages using tags-section.
// This makes a ds-page-section look well inside of an old-style sidebar.
ticket-view-sidebar, task-schedule-edit-page task-schedule-sidebar {
  ds-page-section .page-section {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
    h3 {
      margin-left: -10px;
      margin-top: 0 !important;
    }
  }
}

escalation-steps-table {
  .schedule-actions .ds-dropdown-trigger {
    margin: 0;
  }

  ds-label .high {
    background-color: $cinnabar-red-5 !important;
    border-color: $cinnabar-red-6 !important;
  }
}

schedule-interval-row .time-container .larger-width ds-select {
  width: 160px;
}

ds-rich-text-editor {
  width: 100%;

  .toastui-editor-toolbar-icons {
    &:disabled {
      background-color: unset;
      border: none;
    }
  }
}

cyber-risk-readiness-details-card .crr-card-disabled .cyber-risk-readiness-bar.disabled {
  background-color: $gray-4;
}

pr-dropdown.recent-items-dropdown {
  *[pr-dropdown-trigger] {
    padding-top: 0;
    border-bottom: revert;
  }

  ul[pr-dropdown-body] {
    margin: 0 4px 0 0;
  }

  li {
    font-size: $topbar-link-font-size;
    padding-left: 20px;
    line-height: 32px;
  }

  .recent-items-dropdown-list {
    box-shadow: $menu-box-shadow;
    display: block;

    .dropdown-fixed-text {
      font-style: italic;
    }

    .divider {
      border-top: 1px solid $gray-4;
    }
  }
}

dashboard-custom-editor-zen-mode {
  .content-header .search-filter input[type=text] {
    width: 100%;
  }
}

mapping-framework-line-item-modal {
  .content-header .search-filter input[type=text] {
    width: 100%;
  }
}

dashboard-workspace-dropdown {
  // Undo some undesired side-effects of using pr-dropdowns
  pr-dropdown ul[pr-dropdown-body] li:not(.remove-custom-styles) i:not(.fa-check) {
    padding-right: unset;
    color: unset;
  }
}

dashboard-list-header dashboard-workspace-dropdown .dropdown-label {
  display: none;
}
