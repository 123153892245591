.hover-table {
  .chart-name-cell {
    max-width: rem-calc(200);
    word-break: break-word;
    overflow: auto;
  }

  .chart-type-cell {
    max-width: rem-calc(20);
    overflow-wrap: normal;
    overflow: auto;
  }
}

dashboard-list-page {
  .action-header {
    height: 40px;
  }
}
