
#default-trigger-modal, #default-triggers-modal {
  .query {
    span {
      font-size: rem-calc(14);
    }
  }
}

.ds-dropdown-body .blue-link-options-container {
  li[pr-dropdown-close-on-click] {
    padding-top: 3px;
    padding-bottom: 3px;
  }
}

.library-trigger-group-options-button button {
  padding-top: 16px;
}

library-trigger-group {
  .loading-analysis .loading-container {
    text-align: left !important;
    font-size: 6px;
    i {
      margin-top: 2px;
      margin-bottom: 0;
      margin-right: 8px;
    }
  }
}