
// Idea taken from:
// https://fontawesome.com/v5.15/how-to-use/on-the-web/setup/upgrading-from-version-4
//
// To simplify the migration from FontAwesome 4 to FontAwesome 5, we can force the font-weight on some icons that look
// too bold. This is because in FA5 the icons belong to "fal" instead of "fa".
.fa-angle-down::before,
.fa-columns::before {
  font-weight: 300; // Setting the font-weight to 300 mimics how icons in the "light" group look like
}
