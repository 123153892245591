//TODO: most of this CSS shouldn't be needed now that the page is in Angular2
search-page {
  $half-gutter: $column-gutter * 0.5;
  $export-button-right-margin: rem-calc(12);
  $date-range-picker-left-margin: rem-calc(12);

  .container-sheet {
    margin-top: $half-gutter;
    margin-bottom: $half-gutter;
    background-color: white;
  }

  search-export > a[disabled] {
    opacity: $button-disabled-opacity;
  }

  .search-results-header {
    display: table;
    width: 100%;

    search-count-label, search-export {
      display: table-cell;
    }
    h2 {
      display: inline;
      border: none;
    }
    h4 {
      display: inline;
      font-weight: $font-weight-demibold;
    }
    search-export {
      vertical-align: middle;
      #ticket-export {
        text-align: left;

        li {
          padding: .5rem;

          &.recent-title {
            cursor: default;
            border-bottom: 1px solid $f-dropdown-border-color;
            padding-bottom: .5rem;

            &:hover {
              background-color: $f-dropdown-bg;
            }
          }
        }
      }
    }
  }

  .search-bar {
    flex-grow: 1;
    display: flex;
    background-color: $table-header-bg-color;
    padding: rem-calc(10) rem-calc(16);

    // TODO: Remove this and be more explicit about handlers
    form {
      flex-grow: 1;
      display: flex;
      justify-content: center;
    }

    .new-query-bar {
      max-width: 1276px;
      margin: auto;

      #search-box {
        flex-grow: 2;
        input {
          font-size: rem-calc(14);
          padding-left: rem-calc(16);
          margin: 0;
        }
      }

      #search-help {
        button.help {
          & > i {
            vertical-align: middle;
          }
        }
      }

      #search-submit {
        flex: none;
        width: rem-calc(40);
        height: rem-calc(40);
        padding: 0px;
      }

      #search-save-query {
        margin-left: 10px;
        pr-dropdown {
          .button {
            padding: 0.5rem 1rem 0.4rem;
          }
          *[pr-dropdown-trigger] {
            padding: 0;
            border-bottom: 0;
          }
        }
      }
    }

    #search-help {
      flex: none;
      width: $icon-bar-image-width;
      margin-right: 15px;

      button.help {
        color: $secondary-color;
        & > i {
          vertical-align: bottom;
        }
      }

      div.help {
        position: absolute;
        margin-top: rem-calc(-4); // keep it relative, but bring it up a little
        z-index: z-index(tooltip);
        background: adjust_color($body-bg-color, $lightness: 2%);
        box-shadow: $menu-box-shadow;
        h6 {
          background-color: $gray-5;
          color: $table-header-color;
          font-size: rem-calc(14);
          margin: 0;
          padding: rem-calc(8) $column-gutter rem-calc(4) $column-gutter;
          text-transform: uppercase;
        }
        table {
          background-color: $gray-2;
          border-collapse: initial;
          color: $table-row-font-color;
          padding: rem-calc(8) $column-gutter;

          tr {
            // default table styles are an infectious disease
            background-color: transparent;
            color: $table-row-font-color;
            padding: 0 0 rem-calc(8) 0;

            &:last-child {
              padding-bottom: 0;
            }

            th, td {
              padding: 0;
            }
          }
          th {
            background-color: transparent;
            font-weight: $font-weight-normal;
            line-height: rem-calc(20);
            vertical-align: top;
            width: rem-calc(88);
          }
          td {
            kbd {
              border: none;
              background: transparent;
              color: inherit;
              display: block;
              font-size: smaller;
            }
          }
        }
      }
    }
  }

  #search-results-container {
    position: relative;

    .loading-overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: $body-bg-color;
      opacity: 0.6;

      text-align: center;
      padding: rem-calc(64);
    }
  }

  dl.accordion div.expando-button {
    height: 100%;
    width: 100%;
  }

  .search-graph {
    position: relative;

    > a {
      position: absolute;
      top: 0;
      right: 0;
    }

    date-range-selector .range-selector-container {
      margin-bottom: 4px;
    }

    .graph-top-container {
      display: flex;
      justify-content: space-between;
      margin-top: 5px;
      margin-left: 12px;
      padding-top: 8px;
    }

    .search-graph-section {
      margin-top: 8px;
      margin-bottom: 8px;
      padding-left: 0;
      background-color: white;
    }

    search-graph {
      display: block;
      position: relative;
      padding: 0 8px;

      svg {
        width: 100%;
        height: 100%;
      }

      text {
        fill: $body-font-color;
        font-size: $small-font-size;
      }

      .axis path,
      .axis line {
        fill: none;
        stroke: $body-font-color;
        shape-rendering: crispEdges;
      }
      .axis .tick {
        fill: $body-font-color;
        font-size: 0.875rem;
      }

      .range-line {
        fill: none;
        stroke: rgba($body-font-color, 0.35);
        shape-rendering: crispEdges;
      }

      .selected-line {
        fill: none;
        stroke: $event-color;
        stroke-width: 2px;
        shape-rendering: crispEdges;
      }

      .date-marker {
        position: relative;
        cursor: ew-resize;

        polygon {
          fill: rgba($body-font-color, .7);
          stroke: none;
        }
        path.inner-edge {
          stroke: $body-font-color;
        }
        .gripper {
          stroke: $gray-9;
          fill: none;
          stroke-width: 1px;
        }
      }
    }

    text.marker-text {
      font-size: rem-calc(12);
    }

    g.lines {
      position: relative;
    }
  }
  search-mode-switcher {
    h5 {
      color: $sub-header;
    }
  }

  search-aggregation-table, search-aggregation2d-table, search-aggregation2d-compound-table {
    #group-by-results {
      width: 100%;
    }
    .no-data-text {
      width: 100%;
      padding-left: rem-calc(8);
      padding-top: rem-calc(8);
    }

    .count-table-heading {
      width: 80%;
    }

    td {
      padding: 0;
      border: 1px solid $table-head-bg;
      overflow-wrap: break-word;
      text-align: center;
      line-height: rem-calc(16);
    }
  }

  .lowercase-header-table {
    th {
      text-transform: lowercase;
    }
  }

  search-aggregation-table, search-aggregation2d-table {
    td {
      &:nth-of-type(1) {
        text-align: right;
        background-color: $table-header-bg-color;
        border: 0px;
        border-top: 1px solid $gray-6;
        font-size: rem-calc(12);
        font-weight: $font-weight-bold;
        color: $table-header-color;

      }
    }
  }

  //TODO this is identical to a block above except it doesn't use nth-of-type,
  //convert those to use this
  th.row-header {
    padding-top: 0;
    padding-bottom: 0;
    text-align: right;
    background-color: $table-header-bg-color;
    border: 0px;
    border-top: 1px solid $gray-6;
    font-size: rem-calc(12);
    font-weight: $font-weight-bold;
    color: $table-header-color;
  }

  search-aggregation-table {
    td:nth-of-type(1) {
      padding: rem-calc(2);
    }
    th {
      &:nth-of-type(n+2) {
        border-left: 1px solid $gray-6;
        text-align: center;
      }
      border-bottom: 1px solid $gray-6;
      text-transform:none;
    }
  }

  search-aggregation2d-view {
    .no-results {
      padding: rem-calc(8);
    }
  }

  search-aggregation2d-table {
    table {
      width: 100%;
    }
    tbody {
      overflow: auto;
      max-height: rem-calc(500);
      table-layout: fixed;
    }
    thead {
      table-layout: fixed;
    }
    .table-cell {
      padding: rem-calc(2);
      &.first-col {
        width: rem-calc(144);
      }
    }
    .greyed-out {
      color: $table-head-bg;
    }
    th {
      word-break: break-all;
      padding: 0;
      border-left: 1px solid $gray-6;
      &:nth-of-type(1) {
        border-left: none;
      }
      &.term-cell {
        text-align: center;
        text-transform: none;
      }
    }
    thead:nth-of-type(2) {
      th {
        border-top: 1px solid $gray-6;
      }
    }
    .paging-column {
      width: rem-calc(20);
      .disabled {
        color: $gray-6;
      }
    }

    tr:not(:first-of-type) td.no-horiz-border {
      border-top: none;
    }
    tr:not(:last-of-type) td.no-horiz-border {
      border-bottom: none;
    }

    tr:last-of-type td {
      border-bottom: none;
    }

    td {
      &:last-of-type {
        border-right: none;
      }
    }
    ul.pagination.primary-field {
      min-height: rem-calc(12);
      padding-top: rem-calc(4);
      margin-left: auto;
      margin-right: auto;
      width: rem-calc(480);
      display: block;
    }
    ul.pagination li {
      margin-left: 0px;
    }
  }

  .search-error-notification {
    background: $content-bg-color;
    color: $sub-header;
    text-align: center;
    padding-top: rem_calc(15);
    padding-bottom: rem_calc(15);
  }
}

search-results {
  .search-standalone-button {
    margin: 0 rem-calc(5) 0 rem-calc(5);
  }
}

search-results-table {
  display: block;
  overflow-x: auto;

  pr-dropdown ul[pr-dropdown-body] {
    margin-top: 0px;
  }

  .timestamp {
    width: 200px;
    text-align: center;
  }

  .chevron {
    width: 38px;
    padding: 0 16px;
  }

  .selector {
    width: 36px;
  }

  .custom-column {
    width: 10rem;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }

  .sim-diff-separator-column {
    width: 0.5rem;
    text-align: center;
    padding-left: rem-calc(1);
    padding-right: rem-calc(1);
  }

  .sim-diff-column {
    width: 2.25rem;
    overflow: hidden;
    text-align: center;
    padding-left: rem-calc(1);
    padding-right: rem-calc(1);
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .message-full-width {
    width: 100%;
  }

  .message-fixed-width {
    width: 30rem;
  }

  #search-results {
    table-layout: fixed;

    .similar-field-value-row {
      background: $gray-5;
    }
    .different-field-value-row {
      background: $ds-blue-6;
    }

    .similar-fields-count-button {
      background: $gray-5;
      min-width:1.45rem;
      text-align: center;
      &:hover {
        background: $gray-7;
        color: $gray-1;
      }
    }

    .different-fields-count-button {
      background: $ds-blue-6;
      min-width:1.45rem;
      text-align: center;
      &:hover {
        background: $ds-blue-7;
        color: $gray-1;
      }
    }

    i {
      cursor: pointer;
    }

    td.empty-data {
      width: 0;
    }

    .search-result-details {
      padding: 0;

      table.results {
        border: revert;
        width: 100%;

        .hover-tab {
          width: 4px;
          min-width: 4px;

          &:after, &:before {
            border: revert;
          }
        }

        tr td {
          border-top: revert;

          &.data {
            width: 75%;
            // Kind of ugly - break-word is the ideal value (better view)
            // but firefox does not support it.  By assigning it twice,
            // first the 'fallback' break-all get set, and if available,
            // it immediately resets word-break to break-word (only
            // happens in chrome)  FF ignores invalid assignment.
            word-break: break-all;
            word-break: break-word;
          }
        }

        tr:last-child td {
          border-bottom: revert;
        }
      }
    }

    tr.closed td.message {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .expandable-field-column {
      vertical-align: top;
    }

    .expandable-value-item {
      i.hover-icon {
        color: transparent;
      }

      &:hover i.hover-icon {
        color: $primary-color;
      }

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
}

.search-hint {
  padding: 0.5rem 0 0 1rem;
}

.saved-search-edit {
  position: relative;

  .search-query-original-string {
    border: 1px solid $gray-7;
    padding: 8px;
    color: $gray-7;
    cursor: default;
    font-size: 0.875rem;
  }

  .legacy-saved-query-icon {
    position: absolute;
    top: 24px;
    left: 3px;
    font-size: 1rem;
  }

  .saved-search-name {
    text-indent: 1.5rem;
  }
}

.query-selector-autocomplete-panel {
  max-height: 700px !important;

  &.mat-mdc-autocomplete-panel {
    background: $gray-2;
    padding: 1rem 1.5rem 1.5rem 1.5rem;
  }

  .mat-mdc-optgroup {
    --mat-optgroup-label-text-size: 0.75rem;
    --mat-optgroup-label-text-color: #{$gray-7};
    --mat-optgroup-label-text-weight: 600;

    // Horizontal line separating optgroups
    &::after {
      content: "";
      display: block;
      width: 100%;
      border-bottom: 1px solid $gray-5;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }

    .mat-mdc-optgroup-label {
      padding: 0;
      min-height: unset;
    }

    .mat-mdc-option {
      --mat-option-hover-state-layer-color: #{$gray-3};
      --mat-option-label-text-color: #{$gray-9};
      --mat-option-label-text-size: 0.85rem;
      --mat-option-label-text-line-height: 1rem;

      padding-top: 0.5rem;
      padding-bottom: 0.5rem;

      &:not(.mat-mdc-option-multiple) {
        padding-left: 0;
      }

      .mdc-list-item__primary-text {
        width: 100%;
      }
    }
  }
}

saved-queries-list {
    table > tbody > tr > td {
        max-width: 600px;
    }
}

.saved-query-modal-overlay-panel {
  .mdc-dialog__surface {
    overflow: visible;
  }
  // Overrides the bold fonts from titles
  pr-dropdown {
    font-weight: 400;
  }
}

add-data-restores-modal {
  .date-range-picker-container {
    position: relative;
    box-shadow: unset;
    z-index: unset;
    transform: unset;
    margin: 0;

    .calendar-header {
      margin: 0 20px;
    }

    .next-button {
      margin-right: 8px;
    }
  }
}
