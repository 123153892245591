
fraud-offering-page, risk-offering-page {
  .header {
    padding-top: 1rem;
    padding-bottom: 1rem;
    align-self: center;
  }

  p {
    font-size: 14px;
  }

  .section-container {
    vertical-align: middle;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-top: 1rem;
  }

  .template-container {
    display: contents;
  }

  #branding-icon {
    width: 10rem;
    align-self: center;

    svg {
      fill: $gray-6;
    }
  }

}

threatmatch-page {
  svg-icon.ds-badge .mat-icon {
    width: 20px;
    height: 18px;
  }
}

uba-panels {
  .ds-dropdown-trigger {
    color: $ds-blue-6;
  }

  .trigger-container {
    border-bottom: 0px !important;
  }

  #footer-row-container {
    padding: 0px;
  }
}

single-alert-view {
  .sub-header-action-container {
    float: right;

    .separator-column {
      color: $gray-5;
      font-size: 0.875rem;
      font-weight: normal;
      line-height: 1rem;
      margin: 0 16px;
    }
  }

  .info-notification {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    padding: 16px;
    gap: 16px;
    background: $autumn-orange-1;
    border: 1px solid $autumn-orange-4;
    border-radius: 2px;
    align-self: stretch;

    .fa-info-circle {
      font-size: 1.5rem;
      color: $autumn-orange-6;
    }

    .info-text-container {
      display: flex;
      flex-direction: column;

      .no-events-available-text {
        font-weight: $font-weight-demibold;
      }

      .expired-events-text {
        font-weight: normal;
      }
    }
  }
}
