$calendar-body-preview-cell-border: dashed 1px;

.mat-mdc-snack-bar-container {
  --mdc-snackbar-supporting-text-color: #{$gray-9};
  --mdc-snackbar-container-color: #{$gray-2};
  --mdc-snackbar-supporting-text-font: #{$serif-family};
}

mat-snack-bar-container.mat-mdc-snack-bar-container {
  margin: 24px; // Adding this extra margin to retain the current look of the notifications
}


.mat-mdc-dialog-container {
  --mdc-dialog-container-color: #{$gray-4};

  --mdc-dialog-subhead-font: #{$header-font-family};
  --mdc-dialog-subhead-size: #{$h1-font-size};
  --mdc-dialog-subhead-weight: #{$font-weight-bold};

  --mdc-dialog-supporting-text-font: #{$body-font-family};
  --mdc-dialog-supporting-text-tracking: 0px;
  --mdc-dialog-supporting-text-size: 0.875rem;
  --mdc-dialog-supporting-text-color: #{$body-font-color};

  .mat-mdc-dialog-title+.mat-mdc-dialog-content {
    padding-top: 1.5rem !important;
  }

  .mat-mdc-dialog-title {
    display: flex;
    align-items: center;
    padding: 8px 24px 7px;
  }

  .mat-mdc-dialog-content {
    padding: 1.5rem;
    background-color: $gray-1;
    height: calc(100% - 115px); // 115 is the sum of the footer and header's height
    max-height: 75vh; // Originally, 65vh but the footer in some modals have too much spacing

    h1 {
      display: block;
      margin: 0.5rem 0 0.25rem 0;
      padding: 0;
      border-bottom: $border-rule;
      font-size: rem-calc(11);
      line-height: 150%;
      font-weight: $font-weight-demibold;
      text-transform: uppercase;
      color: $sub-header;
    }

    section {
      margin: 0.25rem 1rem 1rem 1rem;
    }

    p {
      font-size: rem-calc(15);
      margin-bottom: .5rem;
      margin-top: .5rem;
    }

    ul {
      font-size: rem-calc(14);
    }

    .extra-margin-top {
      margin-top: rem-calc(20);
    }

    .extra-padding-left {
      padding-left: 1rem;
    }
  }

  .mat-mdc-dialog-actions {
    min-height: 24px;
    padding-bottom: 12px;
    padding-right: 24px;
  }
}

.mat-mdc-tooltip {
  --mdc-plain-tooltip-supporting-line-height: 1rem;
  --mdc-plain-tooltip-supporting-text-size: .625rem;
}

.mat-mdc-tab {
  --mat-tab-header-label-text-tracking: normal;
}

.mat-mdc-tab-header {
  border-bottom: 1px solid #{$gray-4};
}

.mat-mdc-tab-group {
  --mat-tab-header-active-label-text-color: #{$body-font-color};
  --mat-tab-header-active-focus-label-text-color: #{$body-font-color};
  --mat-tab-header-active-hover-label-text-color: #{$body-font-color};
  --mat-tab-header-active-focus-indicator-color: #{$ds-blue-6};
  --mat-tab-header-active-hover-indicator-color: #{$ds-blue-6};
  --mdc-tab-indicator-active-indicator-color: #{$ds-blue-6};
  --mat-tab-header-active-ripple-color: #{$ds-blue-6};
  --mat-tab-header-inactive-ripple-color: #{$ds-blue-6};
  --mat-tab-header-inactive-label-text-color: #{$inactive-font-color}
}

// Override our own buttons' CSS that were affecting Material pagination buttons
.mat-mdc-tab-header-pagination-controls-enabled button.mat-mdc-tab-header-pagination {
  background-color: unset;

  &:hover, &:focus {
    background-color: #{$ds-blue-2};
  }
}

.mat-calendar {
  font-family: $sans-serif-family;
  font-weight: $font-weight-demibold;

  .mat-calendar-table-header th {
    font-size: 12px;
    color: $gray-7;
    border-bottom: none;
    text-transform: uppercase;
  }

  .mat-mdc-button {
    font-family: inherit;
    font-weight: inherit;
  }

  .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    border-color: $ds-blue-6;
    background: $ds-blue-1;
  }

  .mat-calendar-body-cell-content {
    font-size: rem-calc(12);
  }

  .mat-calendar-body-label, .mat-calendar-period-button {
    font-size: rem-calc(16);
  }

  .mat-calendar-body-selected {
    border-color: unset;
    background-color: $ds-blue-6;
  }

  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover,
  .cdk-keyboard-focused .mat-calendar-body-active,
  .cdk-program-focused .mat-calendar-body-active {
    .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
      background-color: $ds-blue-2;
    }
  }

  button:hover, button:focus {
    background-color: unset; // Removing unintended custom DefenseStorm CSS
  }
}

.date-time-range-container, .date-range-picker-container, .mat-datepicker-content {
  border-radius: 0 !important;
  box-shadow: 0px 9px 28px 8px rgb(0 0 0 / 4%),
              0px 6px 16px rgb(0 0 0 / 6%),
              0px 3px 6px -4px rgb(0 0 0 / 8%),
              inset 0px 4px 0px $ds-blue-6;
}

.mat-calendar-table {
  border: unset;
}

.mat-calendar-table-header {
  background-color: unset;
}

date-picker {
  .date-picker-container {
    display: flex;
    max-width: 160px;
    height: 32px;
  }

  input[type=text] {
    &:hover + mat-datepicker-toggle {
      button.mat-mdc-icon-button {
        color: $ds-blue-6;
      }
    }

    &:focus + mat-datepicker-toggle {
      button.mat-mdc-icon-button {
        color: $ds-blue-7;
      }
    }
  }

  .mat-datepicker-toggle {
    &.mat-datepicker-toggle-active {
      color: $ds-blue-8;
    }

    button.mat-mdc-icon-button, button.mdc-icon-button {
      background-color: unset;
      color: $gray-7;
      line-height: 16px;
      height: 24px;
      width: 24px;
      margin-left: -20px;
      padding: 0;

      mat-icon {
        line-height: 16px;
      }

      &:hover {
        color: $ds-blue-6;
      }

      &:focus {
        color: $ds-blue-7;
      }
    }
  }
}

.mat-badge-content {
  --mat-badge-background-color: #{$ds-blue-4};
}

// So elements like dropdowns and other things within the modal aren't clipped
.pr-dropdown-overflow-visible {
  .mat-mdc-dialog-content, .mat-mdc-dialog-container, .mat-mdc-dialog-surface,
  .mat-mdc-tab-body.mat-mdc-tab-body-active, .mat-mdc-tab-body-content, .mat-mdc-tab-body-wrapper {
    overflow: visible;
  }
}

.view-page .section.pr-dropdown-overflow-visible {
  overflow: visible;
}

.date-range-picker-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: z-index(dropdown);
  margin-top: -0.5rem;
  background: $gray-1;
  transform: scale(0.9);

  .date-range-picker-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.75rem 0.25rem 1.75rem;
    border-bottom: 1px solid $gray-5;

    ds-button svg {
      width: 0.75rem;
      height: 0.75rem;

      &.prev-icon-2 {
        margin-left: -0.35rem;
      }

      &.next-icon-2 {
        margin-right: -0.35rem;
      }
    }

    .next-container, .prev-container {
      display: flex;
      justify-content: space-between;
    }

    .prev-selectors, .next-selectors {
      display: flex;
      flex-wrap: nowrap;
    }

    .calendar-header {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: $font-weight-demibold;
    }

    .prev-button button, .next-button button {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .calendars-container {
    display: flex;
    justify-content: space-between;
    padding: 0 0.5rem 0 0.5rem;
  }

  mat-calendar {
    margin: 1rem 0.25rem -1rem 0.25rem;

    .mat-calendar-header {
      display: none;
    }

    .mat-calendar-body-cell-container {
      padding: 1rem;
    }

    .mat-calendar-body-label{
      opacity: 0;
    }

    .mat-calendar-body-label[colspan="7"] {
      display: none;
    }

    tbody:first-child {
      text-align: right;
    }

    .mat-calendar-table-header-divider {
      display: none;
    }

    .mat-calendar-body-in-range::before {
      background: $ds-blue-2;
    }

    .mat-calendar-body-in-preview .mat-calendar-body-cell-preview {
      border-top: $calendar-body-preview-cell-border;
      border-bottom: $calendar-body-preview-cell-border;
    }

    .mat-calendar-body-preview-start .mat-calendar-body-cell-preview {
      border-left: $calendar-body-preview-cell-border;

      [dir='rtl'] & {
        border-left: 0;
        border-right: $calendar-body-preview-cell-border;
      }
    }

    .mat-calendar-body-preview-end .mat-calendar-body-cell-preview {
      border-right: $calendar-body-preview-cell-border;

      [dir='rtl'] & {
        border-right: 0;
        border-left: $calendar-body-preview-cell-border;
      }
    }
  }
}

date-range-picker {
  .date-range-picker-form, .date-range-inputs {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .date-range-picker-form {
    max-width: 216px;
    border-bottom: 1px solid $gray-6;

    &.has-error {
      border-bottom: 1px solid $alert-color;

      &:hover:not(:disabled) {
        background-color: $cinnabar-red-1;

        ds-button button {
          color: $alert-color;
        }
      }
    }

    &:hover:not(:disabled) {
      background-color: $ds-blue-1;

      ds-button button {
        color: $ds-blue-7;
      }
    }
  }

  .date-range-picker {
    label.date-range-error {
      font-size: 12px;
      color: $cinnabar-red-6;
      margin-top: 8px;
    }

    input {
      width: 80px;
      text-align: center;
      border-bottom: none;
      background-color: transparent;

      &:hover:not(:disabled), &:focus:not(:disabled), &.ng-invalid.ng-touched:not(:focus):not(form):not(.dont-add-error-border) {
        border-bottom: none;
        background-color: transparent;
      }
    }

    span {
      margin: 0 2px;
    }

    svg.calendar-icon {
      width: 12px;
      height: 12px;
    }
  }

  .next-container, .prev-container {
    width: 32%;
  }

  ds-button button {
    padding-right: 0 !important;
  }

  ds-button.active button {
    color: $ds-blue-8;
  }
}

date-range-selector {
  .range-selector-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: rem-calc(16);
    padding-left: rem-calc(20);
  }

  date-range-calendars {
    margin: rem-calc(-16) rem-calc(-25.5) rem-calc(-16) rem-calc(-25.5);
  }

  ul.date-range-controls {
    display: flex;
    white-space: nowrap;
    margin: 0 rem-calc(12) 0 0;
    z-index: 10;

    li:first-of-type {
      border-radius: rem-calc(2) 0 0 rem-calc(2);
    }
    li:last-of-type {
      border-radius: 0 rem-calc(2) rem-calc(2) 0;
    }
  }

  .date-range-displays {
    display: flex;
    align-items: center;
  }

  &.dashboards .date-range-calendars-dropdown .date-time-range-container {
    top: rem-calc(52);
  }

  &.edit-chart-template {
    .range-selector-container {
      margin: rem-calc(8) 0 0 0;
    }

    .date-range-calendars-dropdown .date-time-range-container {
      top: rem-calc(68);
    }
  }

  .date-range-calendars-dropdown {
    display: flex;
    align-items: center;

    span.range-label {
      display: flex;
      align-items: center;
      margin: rem-calc(4) 0 0 rem-calc(4);
      font-size: rem-calc(12);
      font-weight: $font-weight-demibold;
    }

    svg {
      width: rem-calc(12);
      height: rem-calc(12);
    }

    .date-time-range-container {
      display: flex;
      flex-direction: column;
      position: absolute;
      overflow: hidden;
      top: rem-calc(36);
      background: $gray-1;
      z-index: z-index(dropdown);

      .date-time-range-calendars {
        display: flex;

        .date-range-picker-container {
          position: revert;
          box-shadow: none;
          box-sizing: border-box;
          background: revert;

          .next-container, .prev-container {
            width: 30%;
          }

          .prev-selectors button {
            padding-right: rem-calc(16);
          }

          .next-selectors button {
            padding-right: 0;
            padding-left: rem-calc(16);
          }
        }
      }

      .time-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-top: rem-calc(4);
        padding: 0 rem-calc(16);
        border-left: 1px solid $gray-5;

        input {
          text-align: center;
          width: 3vw;
        }

        .time-begin-container, .time-end-container {
          display: flex;
          flex-direction: column;

          label.date-range-error {
            font-size: rem-calc(8);
            color: $cinnabar-red-6;
            margin-top: rem-calc(4);
          }

          span {
            font-size: rem-calc(12);

            &.date {
              font-weight: $font-weight-demibold;
            }
          }

          .time-begin-controls, .time-end-controls {
            display: flex;

            span {
              margin: 0 rem-calc(8);
            }
          }
        }

        .time-end-container {
          margin-top: rem-calc(16);
        }
      }

      .date-time-controls {
        display: flex;
        justify-content: flex-end;
        border-top: 1px solid $gray-5;
        padding: rem-calc(16) rem-calc(12);

        button {
          margin: 0 rem-calc(4);
        }
      }
    }
  }

  .date-range-display {
    display: flex;
    align-items: center;
    margin: rem-calc(4) 0 0 rem-calc(8);
    font-family: $body-font-family;
    font-weight: $font-weight-demibold;
    font-size: rem-calc(12);

    .prev-period button {
      padding-right: 0;
    }

    ds-button svg {
      width: rem-calc(12);
      height: rem-calc(12);
      color: $gray-9;
    }

    span {
      margin: 0 rem-calc(4) 0 rem-calc(4);
      white-space: nowrap;
    }
  }

  .date-range-picker-container {
    margin-top: 0;
    left: rem-calc(88);
  }
}

date-range-dropdown {
  div[pr-dropdown-body] {
    margin-top: -4px;
    border-top: 4px solid $main-accent-color;
  }

  ul.date-range-controls {
    display: flex;
    justify-content: center;
    margin: 12px 8px 12px 8px;

    .range-pr-button {
      white-space: nowrap;
    }
  }

  .date-range-picker-container {
    margin-top: -16px;
    position: revert;
    box-shadow: none;
    box-sizing: border-box;
    background: revert;

    .calendars-container {
      padding: 0;

      .mat-calendar-content {
        padding-bottom: 0;
      }
    }

    .next-container, .prev-container {
      width: 30%;
    }

    .next-selectors button {
      padding-right: 0;
      padding-left: 16px;
    }
  }
}

/**
  Overrides for mat-select
 */

.mat-mdc-select {
  font-family: inherit;
  font-weight: 500;
  border-bottom: 1px solid #BFBFBF;
}

.mat-mdc-option {
  --mat-option-hover-state-layer-color: #{$ds-blue-2};
  --mat-option-label-text-tracking: normal;
}

.autocomplete-input-panel {
  &.mat-mdc-autocomplete-panel {
    border-top: 2px solid $ds-blue-6;
    padding: 0;
  }
}
