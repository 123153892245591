//TODO: most of this CSS shouldn't be needed now that the page is in Angular2
search-page {
  $half-gutter: $column-gutter * 0.5;

  .applied-filters {
    $gap: rem-calc(3);

    border: $border-rule;
    margin: $half-gutter 0 rem-calc(2) 0;
    padding: 0 $gap $gap $gap;
  }

  .filters {
    padding: 0;

    h4 {
      padding: rem-calc(4) rem-calc(8);
      background-color: $table-header-bg-color;
      color: $table-header-color;
      text-transform: uppercase;
      font-size: rem-calc(12);
      font-weight: $font-weight-bold;
      line-height: rem-calc(18);
      margin-bottom: rem-calc(2);

      span {
        font-weight: $font-weight-normal;
        text-transform: none;
      }
    }
  }

  search-filter-selector {
    .filter-term:hover {
      background-color: $gray-5;
    }
  }
}
