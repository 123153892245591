.settings-users {
  h3 {
    border-bottom: $border-rule;
    margin-bottom: rem-calc(2);
  }

  .description {
    font-size: rem-calc(12);
  }

  .two-factor-required {
    cursor: default;
  }

  table {
    width: 100%;
  }

}
