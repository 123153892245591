@media print {
  @page {
    size: 8.5in 11in;
    margin: 0.5in;
  }
}

report-view-page {
  position: absolute;
  top: .5rem;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 1rem 0.5rem;
  overflow-y: auto;

  @media print {
    font-size: 12pt !important;
    h1 { font-size: 28pt; }
    h2 { font-size: 22pt; }
    h3 { font-size: 18pt; }
    h4 { font-size: 16pt; }
    .report {
      width: 100%;
      article { page-break-inside: avoid; }
    }

    report-header {
      position: relative;
      top: 450px;
      font-size: 28pt;

      img.logo {
        max-width: 220px !important;
      }
    }

    report-note .note-with-markdown { margin-top: 15px; }
  }

  report-header {
    > div { max-width: 1070px; }
    img.logo { max-width: 150px; }

    .report-dates {
      font-style: italic;
      margin-top: 15px;
    }
  }

  .printing-section {
    position: relative;
    .printing-section-contents {
      position: fixed;
      text-align: right;
      top: 100px;
      left: 90px;
      width: 100%;
      .printing-section-restore {
        position: fixed;
        left: 115px;
        top: 50px;
      }
      .printing-section-versions {
        position: fixed;
        right: 24px;
        top: 70px;
        button {
          margin-right: 4px;
        }
        label {
          display: inline-block;
          margin-left: 8px;
        }
      }
    }
  }

  .report {
    max-width: 1070px;
    article {
      margin-top: 50px;
    }
    h2 { border-bottom: none; }
    h3 {
      font-weight: bold;
      margin-bottom: 15px;
    }
    h4 {
      font-weight: bold;
      margin-bottom: 15px;
    }
    h6.chart-title {
      margin-top: 20px;
    }
    .badge-number {
      padding: rem-calc(70);
    }
    .top-10-values-msg { text-align: right; }
  }

  report-note {
    textarea {
      margin-top: 15px;
      margin-bottom: 0px;
    }
    .note-with-markdown {
      margin-top: 15px;
      border: 1px solid $gray-6;
      padding: 5px;
      min-height: 137px;
      background: $gray-1;
    }
    .notes-help { float: left; }
  }

  .table-chart.media-chart-heights {
    @media (max-height: $media-huge) { height: initial; max-height: 300px; min-height: 100px; }
    @media (max-height: $media-large) { height: initial; max-height: 300px; min-height: 100px; }
    @media (max-height: $media-medium) { height: initial; max-height: 300px; min-height: 100px; }
    @media (max-height: $media-small)  { height: initial; max-height: 300px; min-height: 100px; }
    @media (max-height: $media-extra-small)  { height: initial; max-height: 300px; min-height: 100px; }
    @media print { height: initial; max-height: initial; width: 1070px; min-height: 100px; }
  }
}
