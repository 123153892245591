ffiec-cat-page, policy-list {
  .content-header {
    padding: 4px 16px;
  }

  .sheet {
    margin-top: rem-calc(5px);
    margin-bottom: rem-calc(10px);
  }
}

.fixed-width-severity {
  display: inline-block;
  width: 1rem;
  height: 1rem;
}

.policies-policy {
  #policy-metadata-row {
    position: relative;

    #policy-metadata, #policy-stats {
      display: inline-block;
    }

    #policy-metadata {
      .metadata-row {
        display: table-row;
        font-size: rem-calc(13);
        & > * { // this selects direct descendants of a .metadata-row
          display: table-cell;
          padding-right: rem-calc(5);
          line-height: 150%;
        }

        & > .header {
          font-weight:bold;
        }
      }
    }

    #policy-stats {
      position: absolute;
      right: 0;
      width: 7rem;
    }
  }
}

.policy-group-table {
  .trigger-cell {
    width: 8%;
  }
  .incident-cell {
    width: 8%;
  }
  .coverage-cell {
    width: 20%;
  }
}

cat-policies-sub-list {
  .top-padded-button-group {
    padding-top: rem-calc(6);

    [pr-dropdown-trigger] {
      padding-top: 0;
      i {
        vertical-align: middle;
      }
    }
  }
}

cat-policy-version-dropdown {
    pr-dropdown:not([ng-version]) {
        ul[pr-dropdown-body] {
            margin-top:-0.85rem;
            li {
                padding-right: rem-calc(20);
                a {
                    display: inline-flex;
                    width: 100%
                }
            }
            .policy-version-name {
                flex:1;
            }
            .lock-icon-space {
                width: rem-calc(24);
                margin-left: rem-calc(6);
                margin-right: rem-calc(6);
                text-align: center;
                display: inline-block;
            }
            li.recent-archived-labels {
                cursor: default;
                &:hover {
                    background-color: $f-dropdown-bg;
                }
            }
            hr {
                margin: 0;
            }
        }
    }
}

cat-policy-version-list {
  .datepicker {
      width: auto;
  }

  date-picker .mat-datepicker-toggle button.mat-mdc-icon-button {
    margin-top: 14px;
  }
}

cat-policy-page, cat-risk-profile, cat-risk-profile-page, framework-view-page {
  // Copied partly from alert-inbox-sheet
  .content-sheet {
    position: absolute;
    top: 88px;
    left: $side-menu-content-left;
    right: $side-menu-content-right;
    bottom: $side-menu-content-bottom;

    padding: 16px;
    margin-right: 16px;
    overflow-y: auto;
  }

  h2 { //TODO: set this style globally
    border: 0;
  }

  h3 { //TODO: set this style globally
    font-weight: $font-weight-bold;
    &.extra-margin {
      margin-top: 10px;
    }
  }

  p, ul {
    font-size: 0.875rem;
  }

  .sub-header {
    margin-left: 0;
    margin-top: 0;
  }

  .line-item-number-label {
    color: $gray-6;
    text-align: right;
  }

  .no-underline:hover {
    text-decoration: none;
  }

  .level-name {
    font-weight: $font-weight-bold;
    font-size: 0.875rem;
    color: $body-font-color;
    vertical-align: middle;
  }
}

framework-tier-target ds-dropdown {
  .ds-dropdown-trigger {
    &.disabled-trigger .trigger-container {
      background-color: transparent !important;
    }
  }
}

cat-policy-page, cat-risk-profile, cat-risk-profile-page {
  pr-dropdown {
    ul {
        //Used because Cat-policy page sets margin-left and margin-right for the whole page, and these
        //margins cause a weird UI issue in the pr-dropdown if nonzero.
        margin-left: 0px;
        margin-right: 0px;
    }
  }

  .level-completion {
      text-transform: none;
      vertical-align: middle;
      padding-left: rem-calc(5);
  }

  .cat-policy-img-caption {
    text-align: center;
  }

  .cat-policy-img {
    display: block;
    margin: 0 auto;
  }

  .collapse-icon {
    font-size: rem-calc(20);
    padding-top: rem-calc(5);
    max-width: rem-calc(30);
  }

  .lineitems-options {
    font-weight: inherit;
  }

  .align-dropdown {
    padding-top: rem-calc(3);
  }

  .line-item-number-label {
    vertical-align: top;
    max-width: 120px;
    word-wrap: break-word;
  }

  .lineitem-option-button {
    margin: 0 5px;
    font-size: rem-calc(13);
    padding: 4px 8px;
  }

  .top {
    vertical-align: top;
  }

  .policy-sections-table {
    margin-top: rem-calc(10);
    margin-bottom: rem-calc(10);
    p {
      margin-bottom: rem-calc(10);
    }
  }
}

cat-risk-profile-results {
  .results-header-cell {
    width: 10%;
    text-align: center;
  }

  h2 { //TODO: set this style globally
    padding-left: 0;
  }
}

cat-policy-results {
  font-size: 0.75rem;

  .vertical-line {
    stroke: $gray-7;
    stroke-opacity: 0.25;
    shape-rendering: crispEdges;
  }

  .horizontal-line {
    stroke: $main-accent-color;
    stroke-width: 3;
    stroke-dasharray: 3 3;
    shape-rendering: crispEdges;
  }

  .horizontal-level-end-circle {
    fill: $main-accent-color;
  }

  .factor-title {
    fill: $gray-7;
    text-transform: uppercase;
  }

  .column-title {
    fill: $main-accent-color;
    text-transform: uppercase;
  }
}

cat-evidence {
  a {
    color: $sub-header;
  }
  .evidence-label {
    font-weight: 500;
    color: $gray-6;
  }
}

#cat-select-line-items-table {
  // Only want the hover tab, not background color change in the tr
  tr {
    @extend .no-hover;
  }

  .inner-content-padder {
    width: 10px;
    border: none;
  }

  .inner-table-container {
    padding: 0;

    table {
      tr:first-child {
        td {
          border-top: 0;
        }
      }
      tr:last-child {
        td {
          border-bottom: 0;
        }
      }
    }
  }
}

.cat-banded-block {
  @extend .banded-block;
  padding: rem-calc(8);
}

.cat-banded-block-title {
  @extend .banded-block-title;
  margin-bottom: rem-calc(5);
}

.cat-banded-block-content {
  @extend .banded-block-content;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: rem-calc(12);
  margin-bottom: 0;
  clear: both;
}

span.cat-banded-block-content {
  p {
    margin-bottom: rem-calc(10);
    margin-left: 1rem;
    font-size: rem-calc(12);
  }
}

framework-edit-page, mapping-framework-edit-page {
  .fixed-page-container {
    overflow-y: auto;
  }

  button {
    margin-top: 0.1rem;
  }

  form {
    display: flex;
  }

  section {
    flex-grow: 1;
  }

  .new-element {
    border: $gray-5 2px dashed;
    margin: 1rem;
    padding: 0.5rem;
    border-radius: 5px;
    text-align: center;

    &:hover {
      border: rgba($main-accent-color, .7) 2px dashed;
      background-color: $gray-2;
      cursor: pointer;
    }
  }

  .inner-sheet:first-of-type, .fixed-right-content {
    margin-top: 0.1rem;
  }

  .inner-sheet {
    margin: 0.5rem 0.1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  label.no-margin-top {
    margin-top: 0;
  }

  .fixed-scrolling-content h3.sub-header {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 1rem;

    &.extra-margin-top {
      margin-top: 1rem;
    }
    &.extra-margin-bottom {
      margin-bottom: 1rem;
    }
  }

  .fixed-page-container .fixed-scrolling-content {
    overflow-y: auto;
  }

  button {
    margin-top: 0.2rem;
  }

  .section-header-container {
    display: flex;
  }

  .section-header-container h3 {
    flex-grow: 1;
  }

  h3.section-collapsed {
    margin-bottom: 0;
    border-bottom: none;
  }

  .section-edit-action {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .section-delete-action {
    padding-left: 0.5rem;
    padding-right: 1rem;
  }
}

framework-maturity-tier-add-edit {
  .page-section {
    background: white;
    padding: 16px 24px 24px;
    margin-left: 16px;
    border: hidden;
  }

  .sub-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    margin-top: 0;

    &.section-header {
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }

  h3 ds-toggle {
    font-size: 18px;
  }

  .sub-section {
    margin-left: 16px;
    margin-right: 16px;
  }

  .tier-name {
    font-size: 12px;
    color: $gray-7;
  }

  .small-padding {
    padding: 4px;
  }

  .sidebar-short-input {
    width: 109px;
    display: inline-block;
  }

  .sidebar-long-input {
    width: 218px;
    display: inline-block;

    &.editing {
      width: 169px;
    }
  }
}

framework-preview {
  .framework-name-container h1 {
    margin-left: 8px;

    &:hover {
      color: $link-color;
    }
  }
}

.markup-formatting p {
  margin-bottom: 0;
}

evidence-comments, evidence-tickets, evidence-task-schedules, evidence-triggers {
  .hover-table {
    border: 1px $gray-5 solid;
  }
}

evidence-comments .action-header {
  margin-top: 0.2rem;
}

evidence-tickets .action-header, evidence-task-schedules .action-header, evidence-triggers .action-header {
  margin-bottom: 0.4rem;
}
