@mixin graph-color($color) {
  rect {
    fill: $color;
  }
  .fa, .fas {
    color: $color;
  }
  &.line path {
    fill: none;
    stroke: $color;
  }
  &.arc path {
    fill: $color;
    stroke: $color;
  }
}

// This created the classes "color-0", "color-1", ... used by our charts
$index: 0;
@each $color in $graph-colors {
  .color-#{$index} {
    @include graph-color($color);
  }
  $index: $index + 1;
}
.color-none {
  @include graph-color($severity-none-color);
}

.color-low {
  @include graph-color($severity-low-color);
}

.color-medium {
  @include graph-color($severity-medium-color);
}

.color-high {
  @include graph-color($severity-high-color);
}

.loading-svg {
  &.unloaded {
    // still take up the appropriate amount of size, but don't display yourself.
    visibility: hidden;
  }
}

.loading-graph {
  position: relative;

  .graph-overlay {
    width:100%;
    height:100%;
    position:absolute;
    top: 0;
    left:0;
    background-color: $body-bg-color;

    table, td {
      margin: 0;
      padding:0;
      border: none;
      width:100%;
      height: 100%;
      text-align: center !important;
      vertical-align: middle !important;
    }
  }

  .spinner {
    opacity: 0.6;
  }
}

.tooltip-graph {
  position: relative;

  .tooltip {
    background: rgba($body-font-color, 0.85);
    color: $gray-2;
    border: 1px solid $gray-2;
    z-index: z-index(tooltip);
    position:fixed;
    display:block;
    padding: $rem-base*0.5;
    height: auto;
    width: auto;
  }
}


.dashboard-graph {
  display: block;
  svg {
    width: 100%;
    height: 100%;

    text {
      font-size: rem-calc(12);
    }

    .axis path,
    .axis line {
      fill: none;
      stroke: $body-font-color;
      shape-rendering: crispEdges;
    }

    .axis .tick {
      fill: $body-font-color;
    }
  }
}

//TODO: seems to not be used, remove later
.top-legend-graph {
  .legend {
    text-align: right;
  }

  .legend-elem {
    display: inline-block;
    margin: 0 $column-gutter*0.5;
    max-width: calc(20% - #{$column-gutter});
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: rem-calc(12);
    padding-right:0.5rem;

    i {
      margin-right: 0.45rem;
    }
    &:last-child {
      margin-right: 2rem;
    }
    cursor: pointer;
  }
}

.right-legend-graph {
  svg, .legend {
    display: inline-block;
    vertical-align: top;
  }

  .legend {
    padding-left: $column-gutter;

    .legend-elem {
      cursor: pointer;

      i, span {
        display: inline-block;
        vertical-align: middle;
        margin: 0 $column-gutter*0.25;
      }

      i {
        width: rem-calc(14);
      }

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 85%;
      }
    }
  }
}

ip-map {
  display:block;
  .ip-map {
    width: 100%;
    background: $body-bg-color;

    .leaflet-overlay-pane {
      svg {
        path {
          stroke: $oil;
          fill: $alert-color;
          fill-opacity: 0.4;
        }
      }
    }

    .leaflet-control-zoom {
      opacity: 0.9;
    }

    .leaflet-popup {
      color: $body-font-color;
    }
    .leaflet-popup-close-button a {
      color: $body-font-color;
    }
  }
}

ticket-pipeline-graph {
  .pipeline-stage, .hover-stage {
    .count-label-small {
      font-size: rem-calc(12);
    }
    .count-label {
      font-size: rem-calc(16);
    }
    .count-label-large {
      font-size: rem-calc(24);
    }

    .count-small {
      font-size: rem-calc(20);
    }
    .count {
      font-size: rem-calc(32);
    }
    .count-large {
      font-size: rem-calc(40);
    }
  }

  .hover-stage polygon {
    stroke-width: 8px;
  }
}

.chart-title {
  // Enforce consistency since the base styles on the respective pages used overwrite defaults :(
  font-size: $base-font-size;
  font-weight: $font-weight-demibold;
}

.chart-subtitle {
    font-size: rem-calc(13px);
    font-weight: $font-weight-normal;
}

.transparent {
  opacity: 0.15;
}

.no-data-message {
  position: absolute;
  top: 45%;
  left: 50%;
  width: 50%;
  margin: 0 -25%;
  text-align: center;
}

.badge .badge-number {
  padding: 10px 30px;

  @media (max-height: $media-huge) {
    font-size: rem-calc(120);
    border-width: rem-calc(15);
  }
  @media (max-height: $media-large) {
    font-size: rem-calc(80);
    border-width: rem-calc(10);
  }
  @media (max-height: $media-medium) {
    font-size: rem-calc(70);
    border-width: rem-calc(9);
  }
  @media (max-height: $media-small)  {
    font-size: rem-calc(60);
  }
  @media (max-height: $media-extra-small)  {
    font-size: rem-calc(50);
  }
}
