//Following heights and width should add up to 100% and should always be changed together.
$alert-list-width: 30%;
$alert-content-width: 70%;

$inbox-header-height: 5%;
$inbox-content-height: 95%;


.temporary-for-demo-hacky-get-rid-off-border {
    pr-dropdown button {
        border-bottom: none;
    }
    margin-right: 1rem;
}

//TODO: remove when removing newAlertInbox feature gate
.new-alert-inbox-test {
  padding-left: 1rem;
}

alert-inbox {
  //NOTE: Move away from using IDs. They are normally not needed and can mess with CSS specificity.

  //Page layout styles:
  //Absolute positioned containers with flex containers inside that rely on absolute positioning
  // These do not need to be nested to be styled correctly.
  // Nested structure was added to clearly show how containers rely on their parents' position.

  .alert-inbox-sheet {
    position: absolute;
    top: rem-calc(50);
    left: $side-menu-content-left;
    right: $side-menu-content-right;
    bottom: $side-menu-content-bottom;

    .inbox-container {
      display: flex;
      flex-direction: column;

      .inbox-container-header-child {
        min-height: rem-calc(35);
        flex-basis: $inbox-header-height;
        display: flex;

        .alert-sort-header {
          flex-basis: $alert-list-width;
          background-color: $gray-1;
        }

        .alert-state-header {
          flex-basis: $alert-content-width;
          background-color: $gray-2;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }

      .inbox-container-alerts-child {
        flex-basis: $inbox-content-height;
        min-height: $inbox-content-height;
      }

      .alerts-container {
        //flex-layout
        display: flex;
        flex-direction: row;

        .alerts-container-content-child {
          //flex child of inbox-content-container
          flex-basis: $alert-content-width;
          min-width: rem-calc(400);
          padding: rem-calc(5);
        }

        .alerts-container-content-child.refresh{
          padding: rem-calc(5) rem-calc(12) 0 rem-calc(16);
          box-shadow: inset 9px 0px 0px -5px $gray-2;
        }
        .alert-info-content-container {
          //flex-container for triggerInfo & rightAlertList
          display: flex;
          flex-direction: column;
          height: 100%;

          .dismissal-detail {
            padding-left: rem-calc(8);
            padding-bottom: rem-calc(10);
            display: flex;
          }
        }

        .alerts-container-list-child {
          flex-basis: $alert-list-width;
        }

        .left-alert-list-container {
          //flex-layout
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  // CSS for small specific components on Alert Inbox Page

  .loading-text {
    padding: rem-calc(10) rem-calc(10) rem-calc(10) rem-calc(20);
    color: $inactive-font-color;
  }

  .scrolling-panel-text {
    @extend .link-text;
    padding: rem-calc(10);
  }

  .alert-list-item {
    font-size: rem-calc(14);
    border-bottom: $border-rule;
    padding: rem-calc(8) 0 rem-calc(8) rem_calc(6);
    border-left: rem-calc(6) solid transparent;

    &:hover, &.active {
      background-color: $body-bg-color;
      border-left: rem-calc(6) solid $main-accent-color;
    }

    .alert-badge {
      margin: rem-calc(3) rem-calc(6) 0 0;
      background-color: $content-bg-color;
      color: $gray-6;
      border: solid rem-calc(1) $gray-6;
      padding-top: rem-calc(6)
    }
  }

  .info-title {
    color: $sub-header;
    font-size: rem-calc(16);
    float: left;
  }

  .limit-results-height {
      max-height: rem-calc(400);
  }

  .alert-state-info-section {
    padding-bottom: rem-calc(12);
    border-left: rem-calc(6) solid transparent;

    &:hover, &.active {
      border-left: rem-calc(6) solid $main-accent-color;
    }
  }

  .alert-state-info-section.refresh {
    padding-bottom: rem-calc(12);
    border: $border-rule;
    margin-left: rem-calc(5);
    margin-bottom: rem-calc(22);

    &:hover, &.active {
      // Need to use box-shadow to avoid the (non-overrideable) css mitering of borders
      box-shadow: rem-calc(-6) 0px 0 $main-accent-color;
      margin-left: rem-calc(6);
      border-left: none;
    }
  }

  .state-bar {
    //flex-layout
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    background-color: $gray-2;
    padding-top: rem-calc(4);
    margin-bottom: rem-calc(8);
    max-height: rem-calc(35);

    alert-state-info-bar span {
      padding-left: rem-calc(5);
      display: inline-flex;
      align-items: baseline;
    }

    .icon {
      color: $gray-6;
      margin: 0 rem-calc(15) 0 rem-calc(15);
    }
  }

  alert-state-changer {
    .button {
      padding-top: 0;
      padding-bottom: 0;
      &:hover {
        text-decoration: none;
      }
    }
  }

  #cause-text {
    font-weight: $font-weight-demibold;
    color: $body-font-color;
    margin-bottom: rem-calc(10);
  }

  #alert-timestamp {
    float: right;
    text-align: right;
    font-size: rem-calc(12);
    word-wrap: break-word;
  }

  .inbox-zero {
    width: 100%;
    text-align: center;
    align-self: center;

    .inbox-zero-header {
      font-weight: $font-weight-demibold;
    }

    #inbox-zero-img-box {
      width: rem-calc(300);
      height: rem-calc(200);
      display: inline-block;

      svg {
        fill: $main-accent-color;
      }
    }
  }

  .trigger-graph-container {
    margin-top: 0;
  }

  hr {
    margin: rem-calc(10) 0;
  }

  .sort-dropdown {
    margin-left: rem-calc(24);

    [pr-dropdown-trigger] {
      padding-top: rem-calc(8);
    }
  }
}

trigger-header {
  #trigger-header {
    display: block;
    background-color: $content-bg-color;
    padding: rem-calc(5) rem-calc(8) 0 rem-calc(4);

  }
  .sticky {
    position: sticky;
    top: 0;
    width: 100%;
    align-items: center;
    z-index: 10;
  }
}

// Needs to be in a global file because it affects one Angular component only when inside another, so CSS encapsulation
// won't allow us to put this style in the parent component
single-org-alert-list dismiss-button button.secondary {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.sequence-alert-result-step-arrow {
  fill: $gray-2;
  line-height: rem-calc(12);
  height: rem-calc(12);
  text-align: left;
}

.sequence-alert-result-step-container {
  background-color: $gray-2;
  padding: rem-calc(16);

  .step-title {
    color: $gray-6;
    font-size: rem-calc(14);
    font-weight: 600;
    width: rem-calc(115);
    text-align: left;
  }

  .step-query {
    flex: 1;
    text-align: left;
  }

  .step-trigger-time {
    text-align: right;
    color: $gray-9;
  }
}

.sequence-alert-result-matches {
  padding-left: rem-calc(16);

  .matches-title {
    color: $sub-header;
    font-size: rem-calc(16);
    font-weight: 600;
    text-align: left;
    margin-top: rem-calc(12);
  }

  .sequence-alert-step-match-entry {
    margin-bottom: rem-calc(4);

    .match-key {
      color: $gray-9;
      font-size: rem-calc(14);
      width: rem-calc(100);
      text-align: left;
    }

    .match-key-value-split {
      font-size: rem-calc(14);
      margin: 0 rem-calc(15);
      font-weight: 600;
      color: $gray-9;
    }

    .match-value {
      color: $main-accent-color;
      font-size: rem-calc(14);
      font-weight: 600;
    }
  }
}

single-trigger-display trigger-alerts-table {
  .alert-table-header {
    position: sticky;
    top: 45px;
    width: 100%;
    align-items: center;
    z-index: z-index(content) - 99;
    background-color: $gray-1;
    padding: 8px 0;
  }

  .alert-table-column-headers {
    position: sticky;
    top: 92px;
    width: 100%;
    align-items: center;
    z-index: z-index(content) - 100;
  }
}

trigger-alerts-table {
  .top-border {
    border-top: 1px solid $gray-2;
  }

  #load-more-alerts {
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .alert-view-link, .story-view-link {
    display: inline;
    align-items: center;

    &:hover {
      text-decoration: none;
    }

    .fa-search {
      padding-right: 0.5rem;
    }
    .fa-list {
        padding-left: 0.5rem;
    }
  }

  .risk-factor-list {
    font-size: .875rem;
    margin-bottom: 0;
  }

  .hover-table .timestamp-cell {
    width: 20%;
  }
}

dismiss-form-modal {
  #dismissal-header {
    margin-bottom: rem-calc(24);
  }

  textarea {
    margin-top: rem-calc(20);
  }
}

fraud-inbox-page {
  alert-inbox-header {
    padding-left: 0.5rem;

    .pr-dropdown-padding {
      padding-left: 0.5rem;
    }
  }

  .inbox-main-sheet {
    display: flex;
    position: absolute;
    background-color: $content-bg-color;
    top: rem-calc(50);
    left: $side-menu-content-left;
    right: $side-menu-content-right;
    bottom: $side-menu-content-bottom;

    &.story-mode {
      left: rem-calc(18);
      right: rem-calc(24);
    }

    &.alert-details-view {
      position: revert;
      margin-right: 8px;
    }

    .inbox-main-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 100%;
    }
  }

  .inbox-left-header {
    display: flex;
    align-items: center;
    min-height: 40px;
    margin: 0 8px;
  }

  .inbox-left-header {
    background-color: $gray-1;
  }

  .inbox-left-container {
    display: flex;
    flex-direction: column;
    flex-basis: $alert-list-width;
  }

  .inbox-right-container {
    display: flex;
    flex-direction: column;
    flex-basis: $alert-content-width;
    min-width: rem-calc(400);
    box-shadow: inset 9px 0 0 -5px $gray-2;

    .inbox-right-scrollable {
      overflow-y: auto;
      height: 100%;
      padding: 0 12px 0 16px;
    }
  }

  .info-title {
    color: $sub-header;
    font-size: rem-calc(16);
    float: left;
  }

  .scrolling-panel {
    overflow-y: auto;
  }

  .vh-center-content {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
  }

  alert-state-changer {
    .button {
      padding-top: 0;
      padding-bottom: 0;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

single-alert-display {
  trigger-header {
    #trigger-header {
      // because the header doesnt stretch the entire width due to inclusion of
      // alert state change buttons on the same row
      border-bottom: none;
    }
  }

  #primary-term-container {
    display: inline-block;
    width: 100%;
    margin-left: 0;
    padding-left: 1.25rem;
    padding-right: 1.25rem;

    .primary-term {
      border-top: 1px solid $gray-5;
      border-bottom: 1px solid $gray-5;
      padding-top: rem-calc(14);
      padding-bottom: rem-calc(14);
    }
  }

  search-results-table {
    .important-field-key {
      color: $gray-9;
      font-weight: $font-weight-demibold;
    }

    search-results-table-detailed-value {
      threat-rating pr-dropdown > span {
        height: 20px;
      }
    }
  }
}

single-alert-view {
  width: 100%;

  events-table search-results-table {
    margin-bottom: 16px;
  }

  trigger-header {
    #trigger-header {
      padding: 0;
      border-bottom: none;
      font-size: 1.25rem;
      line-height: 1.5rem;

      #edit-trigger {
        position: absolute;
        top: 0;
        right: 0;
        padding: 12px 16px;
      }
    }
  }

  single-alert-view-header {
    ds-button.hyperlink-button button {
      padding-top: 0;
      padding-bottom: 0;
      height: unset;
    }
  }

  alert-state-changer .button {
    margin: 0 4px;
    padding: 0 4px;

    &.link.secondary {
      color: $gray-6;
    }

    svg-icon.flag-fraud .mat-icon {
      fill: $gray-6;
    }
  }

  important-fields-container {
    ds-dropdown.important-field-selector {
      .trigger-container {
        border-bottom: transparent;
        color: $ds-blue-6;

        &:active, &:focus {
          border-bottom: transparent;
        }

        i {
          font-size: 0.75rem;
        }
      }

      #ds-dropdown-trigger {
        flex-grow: unset;
      }
    }
  }

  .sub-header-action-container {
    ds-button button {
      padding-right: 0;
    }
  }
}

.important-field-cdk-pane {
  ul.ds-dropdown-body {
    max-height: min-content;

    li:hover {
      background-color: transparent;
    }
  }
}

svg-icon.flag-fraud {
  .mat-icon {
    height: 14px;
    width: 14px;
    fill: $gray-7;
  }
}

svg-icon.flag-fraud-sidebar {
  .mat-icon {
    height: 14px;
    width: 10.5px;
    color: $gray-10;
    margin-bottom: -2px;
  }
}

alert-story-mode-viewer, alert-state-changer {
  .button-group {
    display: flex;

    li {
      margin: 0;

      .button.borderless {
        border-color: transparent;
      }
    }

    .action-icon:hover {
      color: $main-accent-color;
    }

    button {
      .action-icon:hover {
        color: $main-accent-color;
      }

      #escalated-icon {
        display: flex;
        align-items: baseline;

        &:hover {
          color: $main-accent-color;
        }
      }

      .bulk-lines {
        display: inline-block;
        align-self: center;
        width: rem-calc(14);
        margin-right: rem-calc(3);
      }
    }

    .disabled-button {
      &.secondary {
        border-color: transparent;
        cursor: $cursor-disabled-value;
      }

      i {
        color: $gray-6;

        &:not(:focus):hover {
          color: $gray-6;
        }
      }
    }

    #escalate-button {
      padding: 0 rem-calc(10) 0 rem-calc(16);

      &:hover {
        text-decoration: none;
      }
    }

    .recent-title {
      padding-bottom: rem-calc(3);
      font-weight: $font-weight-demibold;

      &:hover {
        background-color: $content-bg-color;
      }
    }
  }
}

alert-story-mode-viewer {
  .inbox-left-container {
    flex-basis: 25%;
  }

  .inbox-right-container {
    flex-basis: 75%;
    padding: 5px 16px 0 16px;

    section {
      height: 100%;
    }
  }

  header.inbox-left-header {
    font-size: 12px;
    font-weight: $font-weight-demibold;
    color: $gray-7;
    justify-content: flex-start;
    text-align: left;
    margin: rem-calc(8) 0 0 rem-calc(16);
  }

  header.content-header {
    position: absolute;
    top: rem-calc(-40);
    right: rem-calc(-20);
    border-bottom: none;
  }

  h2 {
    display: flex;
    justify-content: space-between;
    padding: 0;

    .aggregate-title {
      color: $sub-header;
      margin-right: rem-calc(4);
    }
  }

  pr-dropdown.export-dropdown {
    *[pr-dropdown-trigger] {
      border-bottom: none;

      &:hover, &.active, &:focus {
        border-bottom: none;
      }
    }

    span {
      color: $sub-header;
      font-size: 12px;
      font-weight: $font-weight-demibold;
      padding: 5px 12px;
    }

    li {
      font-weight: $font-weight-normal;
      padding: 8px 16px;
    }
  }

  .story-table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: rem-calc(16) 0 rem-calc(4) 0;

    .tab-group {
      display: flex;
      margin-left: 0;
      box-shadow: inset 0px -1px 0px $table-header-bg-color;
    }

    .table-title {
      display: flex;
      align-items: center;
      padding-bottom: 16px;
      cursor: pointer;

      &:first-child {
        margin-right: 24px;
      }

      &.active {
        box-shadow: inset 0px -4px 0px $main-accent-color;
      }
    }

    .title-text {
      font-size: $base-font-size;
      line-height: 20px;
      font-weight: $font-weight-bold;
    }

    .table-count {
      font-size: 11px;
      line-height: 16px;
      font-weight: $font-weight-normal;
      color: $gray-7;
      border: 1px solid $gray-6;
      box-sizing: border-box;
      border-radius: 12px;
      margin-left: 10px;
      padding: 2px 8px 0px 8px;
    }

    .button-group {
      align-items: center;

      .link-text {
        font-size: 14px;
        line-height: 16px;
      }

      ds-button button {
        margin: 0;
        padding-right: 0.5rem;
      }
    }

    .vertical-line {
      width: rem-calc(22);
      border: 0.5px solid $gray-5;
      transform: rotate(90deg);
    }
  }

  .alert-table-footer {
    display: flex;
    justify-content: space-between;
    flex-flow: row-reverse;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 12px 28px;
    background-color: $gray-1;
    box-shadow: inset 9px 0px 0px -5px $gray-2, inset 0px 3px 0px rgba(0, 0, 0, 0.07);
  }

  .aggregate-list-item {
    font-size: 14px;
    font-weight: $font-weight-demibold;
    border-bottom: $border-rule;
    padding: 8px 0 8px 6px;
    border-left: 6px solid transparent;

    &:hover, &.active {
      background-color: $body-bg-color;
      border-left: 4px solid $main-accent-color;

      div.clearfix {
        padding-left: 2px;
      }
    }

    .alert-badge {
      border: 1px solid $gray-6;
      box-sizing: border-box;
      border-radius: 12px;
      padding: 0px 8px;
      margin: 0px 4px;
    }
  }

  .aggregate-selector-container {
    @extend .full-sized;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .return-to-top-container {
      padding: 12px;
      box-shadow: inset 0px 3px 0px rgba(0, 0, 0, 0.07);
    }

    .return-to-top-icon {
      vertical-align: middle;
    }
  }

  alert-story-alerts-table, search-results-table {
    table:not(.results) {
      display: block;
      overflow-y: scroll;
      overflow-x: hidden;
      height: 100%;

      tr {
        display: table;
        width: 100%;
        table-layout: fixed;
      }

      thead {
        position: sticky;
        top: 0;
        z-index: z-index(content) - 100;
      }

      tbody td {
        border-top: revert;
      }
    }

    table.results {
      margin-left: 40px;
    }
  }

  search-results-table, alert-story-alerts-table table:not(.results) {
    height: calc(100% - 178px);
  }
}
