task-schedule-edit-page {
  .save-footer {
    height: rem-calc(75);
    right: 0;
    left: rem-calc(5);
    bottom: 0;
    margin: 0 rem-calc(15);
    background-color: $content-bg-color;
  }

  // adds padding to the bottom of the taskScheduleEditView so that the .save-footer doesnt' cover teh bottom section
  .bottom-padded {
    padding-bottom: rem-calc(80);
  }
}

task-schedule-new-page {
  interval-selector {
    select[name="interval-unit"] {
      // default select width - input width - margin
      width: calc(18.75rem - 6rem - 5px);
    }
  }
}

task-schedule-edit-form {
  .query-name-margin-bottom {
    margin-bottom: rem-calc(10);
  }
  .no-margin-top {
    margin-top: 0;
  }
  .section-margin-bottom {
    margin-bottom: 1.2rem;
  }
}

button.inline-add {
  color: $main-accent-color;
  font-size: rem-calc(14);
  padding: 0;

  &.with-padding {
    padding-top: 1rem;
  }

  i {
    font-size: rem-calc(14);
    padding-right: rem-calc(3);
  }

  &[disabled] {
    color: $main-accent-color;
    text-decoration: none;
  }
}

parent-and-subschedules {
  .loading-spinner {
    margin: unset;
  }
}

svg-icon.trash-icon {
  .mat-icon {
    height: 16px;
    width: 16px;
    cursor: pointer;
    fill: $gray-6;
  }

  &.fill-warning .mat-icon {
    fill: $autumn-orange-7;
  }
}
