trigger-view {
  font-size: $sub-nav-font-size;

  .trigger-conditions {
    margin-top: rem-calc(10);
    display: inline-block;
  }

  .instructional-text {
    font-style: italic;
    padding: rem-calc(2) rem-calc(20);
  }

  ul.button-group {
    height: rem-calc(40);
  }

  new-policies-section .section-container {
    padding: 0 14px;
  }
}

edit-trigger {
  .type-table {
    border: 0px;
    margin-bottom: rem-calc(20);

    td {
      padding: rem-calc(2) rem-calc(20) 0 0;
    }
  }
  .dropdown-cell {
    white-space: nowrap;
  }
}

new-trigger-type-selector {
  .panel-container {
    display: flex;
    align-items: stretch;
  }

  .panel {
    margin: rem-calc(8);
    flex: 1;
    transition: background-color 300ms ease-out 0s;

    // TODO: apply this globally?
    h1 {
      padding: 0;
    }

    &:hover {
      background-color: darken($panel-bg, 10%);
    }
  }

  .big-pad-bottom {
    margin-bottom: rem-calc(25);
  }
}

trigger-sidebar {
  display: block;
  margin-bottom: 24px;

  ds-button.trigger-version-button button {
    float: right;
    margin-top: -8px;
    padding-right: 0;
  }
}

trigger-notifications, trigger-info-panel {
  ds-button button.black-link {
    color: $gray-7;

    &:hover {
      color: $cinnabar-red-6;
    }

    &:active {
      color: $cinnabar-red-7;
    }
  }

  trigger-pause-control {
    ds-dropdown {
      .ds-dropdown-trigger {
        margin-top: 0 !important;
        margin-bottom: 0 !important;

        div {
          font-weight: $font-weight-normal !important;
        }
      }
    }
  }
}

trigger-important-fields {
  .section-container {
    padding: 0 14px;
  }

  ds-button button {
    padding: 14px 0 0 0;
  }
}

trigger-aggregation-selector {
  .ion-ios-help {
    font-size: rem-calc(20);
    color: $gray-7;
    margin-left: .75rem;
    position: relative;
    top: rem-calc(2);
  }
}

trigger-threshold-selector {
  .interval-type {
    width: rem-calc(120);
  }
}

interval-selector {
  .interval-unit {
    width: rem-calc(120);
  }
}

sequence-trigger-steps {
  .add-query-container {
    ds-button button {
      padding-top: 0;
    }

    button {
      padding-top: 4px;
    }
  }
}

trigger-configuration {
  .trigger-configuration-section {
    &:not(:last-of-type) {
      margin-bottom: 32px;
    }

    .config-info-message {
      color: $secondary-color;
    }

    .read-only-trigger-name {
      // matching nice-form select dimensions
      font-weight: $font-weight-demibold;
    }

    .trigger-type-description {
      color: $secondary-color;
      font-size: 0.75rem;
      line-height: 1rem;
    }
  }

  .hint-text {
    color: $secondary-color;
  }
}

trigger-query-display {
  saved-query-summary search-link {
    width: 80%;
  }
}

ticket-escalation-settings-modal-view, incident-details-modal {
  person-picker ds-select {
    width: 50%;
  }

  .state-selector {
    display: inline-block;
    margin-left: 4px;

    ds-dropdown {
      .trigger-container {
        border-bottom: 1px solid transparent;

        &:active, &:focus {
          border-bottom: 1px solid transparent;
        }

        #ds-dropdown-trigger, i {
          font-size: 0.75rem;
          font-weight: normal;
          color: $ds-blue-6;
        }
      }
    }
  }
}

trigger-list {
  .resync-button button {
    height: 16px !important;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 8px;
  }
}
