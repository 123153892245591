.settings-sources {

  @mixin input-placeholder { // for styling placeholder text of input elements
    &::-webkit-input-placeholder {@content}
    &:-moz-placeholder           {@content}
    &::-moz-placeholder          {@content}
    &:-ms-input-placeholder      {@content}
  }

  .create-token-warning {
    background-color: $autumn-orange-1;
    color: $autumn-orange-7;
    border: 1px solid $autumn-orange-7;
    margin: rem-calc(20 -5 0 -5);
    padding: rem-calc(0 5 0 5);
  }

  .copy-icon-container {
    color: $gray-9;
    font-size: 18px;
    width: 36px;
  }

  create-token-modal {
    .no-margin-top {
      margin-top: 0;
    }

    %label_base {
      padding: 0;
      border: none;
      font-size: rem-calc(12);
      line-height: 150%;
      font-weight: $font-weight-normal;
      color: $gray-7;

      div .title-header {
        margin: 0;
      }
    }

    label:not(.for-checkbox):not(.date-range-error):not(.no-margin-top) {
      @extend %label_base;
      margin: rem-calc(20) 0 0 0;
    }

    label.no-margin-top {
      @extend %label_base;
      margin: 0 0 0 0;
    }

    %input_textlike_base {
      margin: 0;
      padding: rem-calc(2) 0 0 0;
      height: rem-calc(28);
      border: none;
      box-shadow: none;
      font-size: rem-calc(14);
      font-weight: $font-weight-demibold;
      @include input-placeholder { // styles placeholder text
        color: $input-placeholder-font-color;
        font-weight: $font-weight-normal;
      }
    }

    input[type=text], input[type=email], input[type=number], input[type=password], {
      @extend %input_textlike_base;
    }

    input[type=text][readonly] {
      @extend %input_textlike_base;
      cursor: pointer;
      background-color: transparent;
      border: none;
    }
  }
}
