
integration-delete-button {
  display: inline-block;
  margin: 0 rem-calc(8);

  i.ion {
    font-size: rem-calc(20);
  }
}

#help-text {
  margin-top: rem-calc(25);
  font-size: rem-calc(12);
  line-height: rem-calc(20);
}


azure-integration-page {
  .azure-orgs-container {
    text-align: center;
  }
}

finxact-integration-page {
  .finxact-integrations-container {
    text-align: center;
  }
}

sales-force-integration-page {
  .salesForce-orgs-container {
    text-align: center;
  }
}

.source-list {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  li {
    margin: rem-calc(15);
    margin-left: rem-calc(25);
  }
}

.multi-box {
  margin-top: rem-calc(20);
  height: rem-calc(202);
  box-shadow: rem-calc(10) rem-calc(-10) rem-calc(0) $body-bg-color,
  rem-calc(20) rem-calc(-20) rem-calc(0) $body-border-color;

  section div {
    .title-footer {
      padding-top: rem-calc(20);
    }
  }
}

.display-box {
  border: $border-rule;

  .header {
    background-color: $body-bg-color;
    color: $body-font-color;

    .integrated {
      background-color: $grass-green-6;
      height: 100%;
    }

    .active-header {
      background-color: $grass-green-6;
    }

    .expired-header {
      background-color: $alert-color;
      color: $light-text-for-dark-background;
    }
  }

  .logo-icon svg-icon mat-icon {
    width: 100%;
    height: 100%;
  }

  .time-box {
    border: $border-rule;
    border-top: solid rem-calc(2) $body-border-color;

    .time-title {
      color: $gray-6;
      float: left;
      padding: rem-calc(3);
    }
    .time-display {
      float: right;
      padding: rem-calc(3);
      color: $gray-6;
    }
  }
}

.activate-open-dns {
  text-align: center;
  margin: rem-calc(20);
}

.delete-warning-section {
  text-align: center;

  .warning-text {
    margin: rem-calc(15);
  }

  .delete-azure-org {
    margin: rem-calc(20);
    background-color: $content-bg-color;
    border: solid 1px $gray-7;
    color: $primary-color;
    font-weight: $font-weight-demibold;
    line-height: 100%;

    &:hover {
      background-color: $delete-button-color;
      color: $light-text-for-dark-background;
    }
  }

  .delete-salesforce-org {
    margin: rem-calc(20);
    background-color: $content-bg-color;
    border: solid 1px $gray-7;
    color: $primary-color;
    font-weight: $font-weight-demibold;
    line-height: 100%;

    &:hover {
      background-color: $delete-button-color;
      color: $light-text-for-dark-background;
    }
  }

  #deletion-enabled {
    font-weight: $font-weight-bold;
    color: $delete-button-color;
    margin: rem-calc(40);
  }
}

.delete-org {
  .modal-footer button.commit.default {
    background-color: $delete-button-color;
  }
}
